import { Route, Switch } from 'react-router-dom';
import { SsoCallout } from 'app/views/login/ssoLookup/Callout';
import { LoginDifficultiesCallout } from 'app/views/login/entry/Callout';
import React from 'react';
import { LOGIN } from 'shared/paths/login';
import { observer } from 'mobx-react';

/**
 * Route switch used to determine what should be shown at the bottom of the login section
 */
export const LoginCallouts = observer(() => (
  <Switch>
    <Route exact path={LOGIN.SSO_LOOKUP} component={SsoCallout} />
    <Route exact path={LOGIN.ENTRY} component={LoginDifficultiesCallout} />
  </Switch>
));
