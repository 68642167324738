import React, { Component } from 'react';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { Hotkeys, Hotkey, HotkeysTarget, Overlay } from '@blueprintjs/core';
import moment from 'moment';
import { Box, Button, Flex, Grid, Link, Icon, Spinner, Text, ToggleButtonGroup } from 'core/components';
import { InputGroup } from 'core/form';
import SearchResults from 'app/components/search/SearchResults';
import storeLoader from 'app/stores/storeLoader';
import { PiClockCounterClockwiseBold } from 'react-icons/pi';
import { ReactComponent as EmptyStarIcon } from 'app/assets/icons/empty_star.svg';
import { SearchSuggestions } from './SearchSuggestions';
import { SearchSuggestionsPopover } from './SearchSuggestionsPopover';
import { RESULT_TYPE_MAP } from './results/ResultTypeMap';

const GlobalSearchOverlay = styled(Overlay)`
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  background: rgba(37, 37, 37, 0.98);
`;

const GlobalSearchContent = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 8px;
  position: relative;
  @media (max-width: 1300px) {
    max-width: 768px;
  }
`;

const SearchInput = styled(InputGroup)`
  input {
    background: #171717;
    color: #ffffff;
    border-color: #323232;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.45);

    &.bp4-input:focus,
    .bp4-input.bp4-active {
      box-shadow:
        0 0 0 1px #157ff3,
        0 0 0 3px rgba(21, 127, 243, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }

  svg {
    fill: #e3e3e3;
  }
`;

@storeLoader('$dashboards.collection', '$savedViews.collection')
@inject('$app', '$search', '$auth', '$setup', '$recentlyViewed')
@HotkeysTarget
class GlobalSearch extends Component {
  static defaultProps = {
    delay: 400
  };

  state = {
    searching: false,
    searched: false,
    results: [],
    favorites: {},
    recent: [],
    categories: [],
    query: '',
    activeTab: 'Favorites',
    selectedIdx: 0
  };

  queryTimer;

  queryPromise;

  componentDidMount() {
    this.selectedItemRef = React.createRef();
  }

  componentWillUnmount() {
    clearTimeout(this.queryTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const { query } = this.state;
    if (isOpen !== prevProps.isOpen && isOpen) {
      this.refreshLists();
    }
    if (isOpen !== prevProps.isOpen && !isOpen) {
      this.resetSearch();
    }
    if (query && !prevState.query) {
      this.handleTabChange('Results');
    }
  }

  refreshLists() {
    const { $setup, $recentlyViewed } = this.props;
    const favorites = $setup.getFavorites();
    const recent = $recentlyViewed.collection.models.slice().reverse();
    let activeTab = 'Favorites';
    if (Object.keys(favorites).length === 0) {
      activeTab = recent.length === 0 ? 'Results' : 'Recent';
    }
    this.setState({ favorites, recent, activeTab });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    const { isOpen } = this.props;
    const { activeTab: activeTabOnRender } = this.state;

    if (!isOpen) {
      return <></>;
    }

    // Results tab up/down/enter is handled by the SearchResults component
    return (
      <Hotkeys>
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="left"
          label="Switch tabs left"
          onKeyDown={() => {
            const { activeTab } = this.state;
            if (activeTab !== 'Favorites') {
              this.setState({ activeTab: activeTab === 'Recent' ? 'Favorites' : 'Recent', selectedIdx: 0 });
            }
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="right"
          label="Switch tabs right"
          onKeyDown={() => {
            const { activeTab } = this.state;
            if (activeTab !== 'Results') {
              this.setState({ activeTab: activeTab === 'Recent' ? 'Results' : 'Recent', selectedIdx: 0 });
            }
          }}
        />
        {activeTabOnRender !== 'Results' && (
          <Hotkey
            allowInInput
            preventDefault
            global
            group="Global Search Overlay"
            combo="up"
            label="Select results up"
            onKeyDown={() => {
              const { selectedIdx } = this.state;
              const listLength = this.getActiveListLength();
              this.setState({ selectedIdx: selectedIdx ? selectedIdx - 1 : listLength - 1 }, () => {
                /* eslint-disable no-unused-expressions */
                this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
              });
            }}
          />
        )}
        {activeTabOnRender !== 'Results' && (
          <Hotkey
            allowInInput
            preventDefault
            global
            group="Global Search Overlay"
            combo="down"
            label="Select results down"
            onKeyDown={() => {
              const { selectedIdx } = this.state;
              const listLength = this.getActiveListLength();
              this.setState({ selectedIdx: selectedIdx < listLength - 1 ? selectedIdx + 1 : 0 }, () => {
                /* eslint-disable no-unused-expressions */
                this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
              });
            }}
          />
        )}
        {activeTabOnRender !== 'Results' && (
          <Hotkey
            allowInInput
            preventDefault
            global
            group="Global Search Overlay"
            combo="enter"
            label="Open selected result"
            onKeyDown={() => {
              const { selectedIdx } = this.state;
              this.selectItem(selectedIdx);
            }}
          />
        )}
        <Hotkey
          allowInInput
          preventDefault
          global
          group="Global Search Overlay"
          combo="esc"
          label="Clear/Close Search"
          onKeyDown={() => {
            const { query } = this.state;
            if (query && query !== '') {
              this.resetSearch();
            } else {
              this.handleSearchClose();
            }
          }}
        />
      </Hotkeys>
    );
  }

  getActiveListLength() {
    const { activeTab, favorites, recent, results } = this.state;
    if (activeTab === 'Favorites') {
      return Object.keys(favorites).length;
    }
    if (activeTab === 'Recent') {
      return recent.length;
    }
    return results.length;
  }

  selectItem(idx) {
    const { activeTab, favorites, recent } = this.state;
    if (activeTab === 'Favorites') {
      const dashboardList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('dashboard'));
      const savedViewList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('saved'));
      const pageList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('page'));
      const savedViewIdxOffset = dashboardList.length || 0;
      const pageIdxOffset = savedViewIdxOffset + savedViewList.length || 0;
      if (idx < dashboardList.length) {
        const { id: idType = '' } = dashboardList[idx];
        const [type = '', id = ''] = idType.split('$');
        const path = `/v4/library/dashboards/${id}`;
        this.handleItemClick({ type, id, path }, 'Favorites');
      } else if (idx - savedViewIdxOffset < savedViewList.length) {
        const { id: idType = '' } = savedViewList[idx - savedViewIdxOffset];
        const [type = '', id = ''] = idType.split('$');
        const path = `/v4/library/saved-views/${id}`;
        this.handleItemClick({ type, id, path }, 'Favorites');
      } else {
        const { id: idType = '' } = pageList[idx - pageIdxOffset];
        const [type = '', id = ''] = idType.split('$');
        this.handleItemClick({ type, id, path: id }, 'Favorites');
      }
    }
    if (activeTab === 'Recent') {
      const dashboardList = recent.filter((model) => model.get('view_type') === 'Dashboard');
      const savedViewList = recent.filter((model) => model.get('view_type') === 'Saved View');
      const savedViewIdxOffset = dashboardList.length || 0;
      if (idx < dashboardList.length) {
        const { view_id, view_type } = dashboardList[idx].get();
        const path = `/v4/library/dashboards/${view_id}`;
        this.handleItemClick({ type: view_type, id: view_id, path }, 'Recents');
      } else {
        const { view_id, view_type } = savedViewList[idx - savedViewIdxOffset].get();
        const path = `/v4/library/saved-views/${view_id}`;
        this.handleItemClick({ type: view_type, id: view_id, path }, 'Recents');
      }
    }
  }

  handleItemClick = (item, tab) => {
    const { $auth, onNavigate } = this.props;
    const { type: destination_screen_type } = item;
    const { query: search_term } = this.state;

    // Journeys passes in a path and options
    // options: { prompt: 'how do i install nms agent?' },
    const options = item.options || {};

    $auth.track('Global Search', {
      tab,
      destination_screen_type,
      search_term: search_term || 'no_search'
    });

    onNavigate(item.path, options);
    this.resetSearch();
  };

  handleQueryChange = (e) => {
    const { $search, delay } = this.props;
    const query = e.target.value;

    clearTimeout(this.queryTimer);

    this.setState({ query });

    if (query !== '') {
      this.setState({ searching: true });
      this.queryTimer = setTimeout(() => {
        if (this.queryPromise) {
          this.queryPromise.cancel();
        }

        this.queryPromise = makeCancelable($search.query(query));

        this.queryPromise.promise
          .then((response) => {
            const results = $search.getSortedResults(response);
            const categories = Object.keys(response).map((category) => ({
              name: category,
              active: response[category].length > 0,
              filtered: response[category].length > 0,
              expanded: false
            }));

            this.setState({
              searching: false,
              results,
              rawResults: response,
              categories,
              searched: true,
              activeTab: 'Results'
            });
          })
          .catch((err) => {
            if (!(err instanceof CanceledError)) {
              console.error(err);
            }
          });
      }, delay);
    }
  };

  handleSearchClose = () => {
    const { onClose } = this.props;
    this.resetSearch();
    onClose();
  };

  resetSearch() {
    if (this.queryPromise) {
      this.queryPromise.cancel();
    }

    this.setState({
      query: '',
      categories: [],
      results: [],
      searched: false,
      activeTab: 'Favorites',
      searching: false
    });
  }

  handleTabChange = (activeTab) => {
    this.setState({ activeTab, selectedIdx: 0 });
  };

  handleMouseOverRow(idx) {
    const { selectedIdx } = this.state;
    if (selectedIdx !== idx) {
      this.setState({ selectedIdx: idx });
    }
  }

  handleToggleExpandCategory = (category) => {
    const { categories } = this.state;
    const newCategories = categories.map((cat) => {
      if (cat.name === category) {
        return { ...cat, expanded: !cat.expanded };
      }
      return cat;
    });
    this.setState({ categories: newCategories });
  };

  handleToggleFilteredCategory = (category) => {
    const { categories } = this.state;
    const isAllFiltered = categories.filter((cat) => cat.active).every((cat) => cat.filtered);
    const newCategories = categories.map((cat) => {
      if (category === 'all') {
        return { ...cat, filtered: true };
      }
      if (category === 'none') {
        return { ...cat, filtered: false };
      }
      if (isAllFiltered) {
        if (cat.name !== category) {
          return { ...cat, filtered: false };
        }
      }
      if (!isAllFiltered) {
        if (cat.name === category) {
          return { ...cat, filtered: !cat.filtered };
        }
      }
      return cat;
    });
    this.setState({ categories: newCategories });
  };

  renderFavoritesPanel() {
    const { $dashboards, $savedViews } = this.props;
    const { favorites } = this.state;
    const dashboardList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('dashboard'));
    const savedViewList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('saved'));
    const pageList = Object.values(favorites).filter(({ id = '' }) => id.startsWith('page'));
    const savedViewIdxOffset = dashboardList.length || 0;
    const pageIdxOffset = savedViewIdxOffset + savedViewList.length || 0;
    if (dashboardList.length === 0 && savedViewList.length === 0 && pageList.length === 0) {
      return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" pt={5} gap={1}>
          <Text fontSize={24} mb={2} lineHeight="26px">
            Did you know...
          </Text>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text fontSize={16}>You can favorite just about any screen, dashboard, saved view, or resource?</Text>
            <Text fontSize={16}>They&apos;ll show up here, and you can navigate immediately.</Text>
          </Flex>
          <Icon icon={EmptyStarIcon} iconSize={250} mt={4} />
          <Flex gap="4px" alignItems="center" justifyContent="center">
            <Text small>
              Hint: Did you know you can favorite the
              <Link mx="4px" to="/v4/nms/devices" onClick={this.handleSearchClose}>
                NMS devices
              </Link>
              screen? Go ahead and look for the
            </Text>
            <Icon icon="star-empty" iconSize={14} pb="2px" />
          </Flex>
        </Flex>
      );
    }
    return (
      <Flex flexDirection="column" pb={1} overflow="auto" style={{ scrollbarWidth: 'none' }}>
        {dashboardList.length ? (
          <>
            <Box pb={1} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
              <Text large>Dashboards</Text>
            </Box>
            <Flex flexDirection="column" gap={0}>
              {dashboardList.map(({ id: idType = '', name: nameBackup }, idx) => {
                const [, id] = idType.split('$');
                const { name, description } = $dashboards.getDashboardMetadata(id);
                return this.renderFavoriteRow({
                  type: 'dashboard',
                  name: name || nameBackup,
                  path: `/v4/library/dashboards/${id}`,
                  idx,
                  id,
                  description
                });
              })}
            </Flex>
          </>
        ) : null}

        {savedViewList.length ? (
          <>
            <Box pb={1} mt={2} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
              <Text large>Saved Views</Text>
            </Box>
            <Flex flexDirection="column" gap={0}>
              {savedViewList.map(({ id: idType = '', name: nameBackup }, idx) => {
                const [, id] = idType.split('$');
                const { name, description } = $savedViews.getSavedViewMetadata(id);
                return this.renderFavoriteRow({
                  type: 'savedView',
                  name: name || nameBackup,
                  path: `/v4/library/saved-views/${id}`,
                  idx: idx + savedViewIdxOffset,
                  id,
                  description
                });
              })}
            </Flex>
          </>
        ) : null}

        {pageList.length ? (
          <>
            <Box pb={1} mt={2} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
              <Text large>Screens & Workflows</Text>
            </Box>
            <Flex flexDirection="column" gap="4px">
              {pageList.map(({ id: idType = '', name, metadata = {} }, idx) => {
                const [, id] = idType.split('$');
                const { resultType } = metadata;
                const icon = RESULT_TYPE_MAP[resultType]?.Icon || ((props) => <Icon icon="menu" {...props} />);
                return this.renderFavoriteRow({
                  type: 'screen',
                  icon,
                  name,
                  path: id,
                  idx: idx + pageIdxOffset,
                  id,
                  resultType
                });
              })}
            </Flex>
          </>
        ) : null}
      </Flex>
    );
  }

  renderFavoriteRow({ type, icon, name, path, idx, id, description, resultType }) {
    const { selectedIdx } = this.state;
    const { Component: RecentResult, Icon: IconCmp } = RESULT_TYPE_MAP[type];
    icon = IconCmp || icon;
    const isSelectedItem = idx === selectedIdx;

    return (
      <div key={`${type}.${id}`} ref={isSelectedItem ? this.selectedItemRef : null} style={{ cursor: 'pointer' }}>
        <RecentResult
          key={`${type}.${id}`}
          item={{ type, name, path, id, description }}
          onItemClick={() => this.handleItemClick({ type: resultType || type, id, path }, 'Favorites')}
          // use onMouseMove instead of onMouseEnter/onMouseOver to avoid triggering on scroll
          onMouseMove={() => this.handleMouseOverRow(idx)}
          selected={isSelectedItem}
          IconCmp={icon}
          showFavorite
        />
      </div>
    );
  }

  renderRecentPanel() {
    const { $dashboards, $savedViews } = this.props;
    const { recent } = this.state;
    const dashboardList = recent.filter((model) => model.get('view_type') === 'Dashboard');
    const savedViewList = recent.filter((model) => model.get('view_type') === 'Saved View');
    const savedViewIdxOffset = dashboardList.length || 0;
    if (dashboardList.length === 0 && savedViewList.length === 0) {
      return <SearchSuggestions />;
    }
    return (
      <>
        <Flex flexDirection="column" pb={1} overflow="auto" style={{ scrollbarWidth: 'none' }}>
          <Box pb={1} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
            <Text large>Dashboards</Text>
          </Box>
          {dashboardList.map((model, idx) => {
            const { view_id: id, edate } = model.get();
            const { name, description } = $dashboards.getDashboardMetadata(id);
            const path = `/v4/library/dashboards/${id}`;
            const daysAgo = moment(edate).fromNow();
            return this.renderRecentRow({ type: 'dashboard', name, description, path, idx, id, daysAgo });
          })}
          {savedViewList.length > 0 ? (
            <>
              <Box pb={1} mt={2} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <Text large>Saved Views</Text>
              </Box>
              {savedViewList.map((model, idx_local) => {
                const { view_id: id, edate } = model.get();
                const { name, description } = $savedViews.getSavedViewMetadata(id);
                const path = `/v4/library/saved-views/${id}`;
                const daysAgo = moment(edate).fromNow();
                const idx = idx_local + savedViewIdxOffset;
                return this.renderRecentRow({ type: 'savedView', name, description, path, idx, id, daysAgo });
              })}
            </>
          ) : (
            <></>
          )}
        </Flex>
      </>
    );
  }

  renderRecentRow({ type, name, description, path, idx, id, daysAgo }) {
    const { selectedIdx } = this.state;
    const { Component: RecentResult, Icon: IconCmp } = RESULT_TYPE_MAP[type];
    const isSelectedItem = idx === selectedIdx;

    return (
      <div key={`${type}.${id}`} ref={isSelectedItem ? this.selectedItemRef : null} style={{ cursor: 'pointer' }}>
        <RecentResult
          item={{ type, name, path, id, description }}
          onItemClick={() => this.handleItemClick({ type, id, path }, 'Recents')}
          onMouseEnter={() => this.handleMouseOverRow(idx)}
          selected={isSelectedItem}
          IconCmp={IconCmp}
          showFavorite
          daysAgo={daysAgo}
        />
      </div>
    );
  }

  renderResultsPanel() {
    const { categories, query, results, rawResults, searching, searched } = this.state;

    return (
      <SearchResults
        searching={searching}
        searched={searched}
        results={results}
        rawResults={rawResults}
        categories={categories}
        onItemClick={this.handleItemClick}
        handleToggleExpandCategory={this.handleToggleExpandCategory}
        handleToggleFilteredCategory={this.handleToggleFilteredCategory}
        query={query}
      />
    );
  }

  renderJourneyResultsPanel() {
    const { query, searching } = this.state;

    const results = [
      {
        category: 'journey',
        id: 1,
        type: 'journey',
        name: 'Start a New Journey',
        path: '/v4/core/journeys',
        options: {
          prompt: query
        },
        description: query
      }
    ];
    const rawResults = {
      journey: [
        {
          id: 1,
          type: 'journey',
          name: 'Start a New Journey',
          path: '/v4/core/journeys',
          options: {
            prompt: query
          },
          description: query
        }
      ]
    };

    return (
      <SearchResults
        searching={searching}
        searched
        results={results}
        rawResults={rawResults}
        categories={[]}
        onItemClick={this.handleItemClick}
        handleToggleExpandCategory={this.handleToggleExpandCategory}
        handleToggleFilteredCategory={this.handleToggleFilteredCategory}
        query={query}
      />
    );
  }

  render() {
    const { isOpen } = this.props;
    const { query, searching, activeTab, favorites, recent, results } = this.state;

    return (
      <GlobalSearchOverlay
        key="backdrop"
        hasBackdrop
        isOpen={isOpen}
        backdropProps={{ style: { top: 60 } }}
        canEscapeKeyClose={false}
        onClose={this.handleSearchClose}
        transitionDuration={0}
      >
        <Wrapper className="bp4-dark global-search-overlay-transition">
          <GlobalSearchContent>
            <Grid
              gridTemplateColumns="1fr"
              gridTemplateRows="min-content min-content minmax(100px, 1fr)"
              gridRowGap={0}
              gridGap={0}
              maxHeight="calc(100vh - 16px)"
            >
              <Flex alignItems="center" justifyContent="center">
                <SearchInput
                  autoFocus
                  className="jumbo"
                  placeholder="Search..."
                  onChange={this.handleQueryChange}
                  width="650px"
                  leftIcon="search"
                  rightElement={
                    <Flex gap={1} alignItems="center" height="100%">
                      <Flex gap={1} alignItems="center" justifyContent="center" height="100%">
                        <Text muted small lineHeight="normal">
                          {query ? 'Clear search' : 'Close search'}
                        </Text>
                        <kbd className="bp4-key" aria-label={query ? 'Clear search' : 'Close search'}>
                          esc
                        </kbd>
                      </Flex>
                      {searching && <Spinner size={16} />}
                      <SearchSuggestionsPopover />
                    </Flex>
                  }
                  value={query}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" mt={1}>
                <ToggleButtonGroup selectedValue={activeTab} py={2}>
                  <Button
                    borderRadius="15px"
                    icon="star"
                    minimal
                    activeBg="primary"
                    style={{ marginRight: '12px' }}
                    onClick={() => this.handleTabChange('Favorites')}
                    value="Favorites"
                  >
                    Favorites ({Object.keys(favorites).length || '0'})
                  </Button>
                  <Button
                    borderRadius="15px"
                    icon={PiClockCounterClockwiseBold}
                    iconSize={20}
                    minimal
                    activeBg="primary"
                    style={{ marginRight: '12px' }}
                    onClick={() => this.handleTabChange('Recent')}
                    value="Recent"
                  >
                    Recents ({recent.length || '0'})
                  </Button>
                  <Button
                    borderRadius="15px"
                    icon="search"
                    minimal
                    activeBg="primary"
                    onClick={() => this.handleTabChange('Results')}
                    value="Results"
                  >
                    {`Search Results${results.length ? ` (${results.length})` : ''}`}
                  </Button>
                </ToggleButtonGroup>
                <Flex mt="auto" p={2} gap={3}>
                  <Flex gap={1} alignItems="center">
                    <Text muted small>
                      Switch tabs
                    </Text>
                    <kbd className="bp4-key">←</kbd>
                    <kbd className="bp4-key">→</kbd>
                  </Flex>
                  <Flex gap={1} alignItems="center">
                    <Text muted small>
                      Select results
                    </Text>
                    <kbd className="bp4-key">↑</kbd>
                    <kbd className="bp4-key">↓</kbd>
                  </Flex>
                </Flex>
              </Flex>

              {!searching && activeTab === 'Favorites' && <>{this.renderFavoritesPanel()}</>}
              {!searching && activeTab === 'Recent' && <>{this.renderRecentPanel()}</>}
              {!searching && activeTab === 'Results' && <>{this.renderResultsPanel()}</>}
              {searching && <>{this.renderJourneyResultsPanel()}</>}
            </Grid>
          </GlobalSearchContent>
        </Wrapper>
      </GlobalSearchOverlay>
    );
  }
}

export default GlobalSearch;
