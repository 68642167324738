import Box from 'core/components/Box';
import Flex from 'core/components/Flex';
import React from 'react';
import Card from 'core/components/Card';
import { LoginLogo } from 'app/views/login/components/LoginLogo';
import $store from 'app/$store';
import $auth from 'app/stores/$auth';
import { LoginCallouts } from 'app/views/login/LoginCallouts';
import { observer } from 'mobx-react';

/**
 * Provides the structural elements used to contain login content
 */
export const LoginContentWrapper = observer(({ children }) => (
  <Flex flexDirection="column" height="100%">
    <Card width={460} height="100%" m={2} elevation={4}>
      <Flex flexDirection="column" alignItems="space-between" height="100%">
        <LoginLogo pt="96px" {...($auth.activeUser ? { onClick: $store.logout } : {})} />
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" pb="96px">
          <Box width={300}>{children}</Box>
        </Flex>
        <LoginCallouts />
      </Flex>
    </Card>
  </Flex>
));
