import { useLoginContext } from 'app/views/login/entry/context';
import { Box, Button, Callout, Flex, Heading, Icon } from 'core/components';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { MdLightbulbOutline } from 'react-icons/md';
import { IoMdKey } from 'react-icons/io';
import $auth from 'app/stores/$auth';
import { capitalize } from 'lodash';
import CalloutOutline from 'core/components/CalloutOutline';

export const LoginViaSSO = observer(() => {
  const { ssoCompanyIdentifier } = useLoginContext();
  return (
    <>
      <Heading level={4} mb={2}>
        Sign in to Kentik Portal
      </Heading>
      {$auth.openConfig?.baseUrl && (
        <>
          <CalloutOutline
            icon={MdLightbulbOutline}
            intent="primary"
            title="To skip this page next time..."
            mb={2}
            mt={2}
          >
            Once successful Single Sign-on has been verified, this page can be skipped by appending &quot;
            <code style={{ fontWeight: 'bold' }}>?autologin=true</code>&quot; to the end of the page&apos;s URL.
          </CalloutOutline>
        </>
      )}
      {$auth.ssoErrorMsg && (
        <Callout icon="error" title="Login Failed" my={2}>
          {$auth.ssoErrorMsg}
        </Callout>
      )}
      <Flex flexDirection="column" alignItems="center">
        <Button
          large
          fill
          loading={$auth.authenticated || $auth.authenticating}
          onClick={() => (window.location = `/sso/${ssoCompanyIdentifier}`)}
        >
          <Icon icon={IoMdKey} iconSize={20} pr={2} />
          Sign in to {capitalize(ssoCompanyIdentifier)} with SSO
        </Button>
        <Box mt={2}>
          <a href="https://www.kentik.com/go/get-started">Sign up for free!</a>
        </Box>
      </Flex>
    </>
  );
});
