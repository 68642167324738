import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';
import Recaptcha from 'react-grecaptcha';

import { Box, Button, Callout, Flex, showSuccessToast } from 'core/components';
import { Field, Form, InputGroup } from 'core/form';
import { fields, options } from 'app/forms/config/login';
import { ContentfulDivider } from 'core/components/ContentDivider';
import LinkButton from 'core/components/LinkButton';
import Icon from 'core/components/Icon';
import { IoMdKey } from 'react-icons/io';
import Text from 'core/components/Text';
import PasswordInput from 'core/form/components/PasswordInput';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import { FieldLabel } from 'core/form/components/FieldLabel';
import { twoFactorImport } from 'app/views/login/2fa';
import { useLoginContext } from 'app/views/login/entry/context';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { SsoLookups } from 'app/stores/auth/SsoLookups';
import $auth from 'app/stores/$auth';

const redirectToSso = (company) => {
  const { company_name, company_name_full } = company;
  sessionStorage.setItem('kentik.ssoInitialReferer', $auth.openConfig.initialReferer);
  showSuccessToast(`Opening SSO portal for ${company_name_full}`);
  sessionStorage.setItem('UsedSSOLogin', 'true');
  window.location = `/sso/${company_name}`;
};

export const LoginFormInternal = ({ form, authenticating, authenticated, openConfig, showLoginRecaptcha }) => {
  const { handleSubmit } = useLoginContext();
  const theme = useTheme();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [ssoLookupDetails, setSsoLookup] = useState(null);
  const ref = useRef();

  /**
   * We perform SSO lookups as the user enters their email, in order to determine whether they should be sent to their
   * SSO login portal instead of this one.
   *
   * this function is debounced for two reasons:
   * 1) Since there are many ways for this input to become filled we want to trigger on all of them.
   *    We don't want to trigger multiple fetches, though.
   * 2) We need to delay execution of this function by a tiny amount since the validation runs after standard functions,
   *    and we want to check validation state
   */
  const onEmailChange = useDebouncedCallback(async (field) => {
    if (field.valid) {
      setSsoLookup(
        await SsoLookups.lookup({
          email: field.value,
          // recaptcha will basically never be valid in this context but whatever
          recaptcha: form.getValue('recaptcha')
        })
      );
    } else {
      setSsoLookup(null);
    }
  }, 1);

  const showRecaptcha = openConfig?.recaptchaEnabled && showLoginRecaptcha;
  // if recaptcha is to be shown, update the form rules to make it required.
  form.getField('recaptcha').setRules(showRecaptcha ? 'required' : []);

  const handleSubmitWrapper = useCallback(
    (passedForm, data) =>
      // prefetch lazy-loaded 2-factor page to save time and prevent potential flash of suspense content
      Promise.all([handleSubmit(passedForm, data), twoFactorImport()]).then(() => {
        // reset recaptcha after submit.
        if (showRecaptcha) {
          window.grecaptcha?.reset();
          passedForm.setValue('recaptcha', null);
        }
      }),
    [handleSubmit, showRecaptcha]
  );

  const handleKeyboardSubmit = useCallback(
    (e) => {
      if (e && e.keyCode === 13) {
        if (openConfig && form.dirty && form.valid) {
          form.submit(handleSubmitWrapper);
        }
      }
    },
    [form, handleSubmitWrapper, openConfig]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardSubmit);
    return () => document.removeEventListener('keydown', handleKeyboardSubmit);
  }, [handleKeyboardSubmit]);

  useEffect(() => {
    // password managers only *appear* to fill out the email box before interaction takes place.
    // blur forces it to fill out, thereby updating SSO state.
    ref.current?.blur();
  }, []);

  const recaptchaCallback = useCallback(
    (response) => {
      form.setValue('recaptcha', response);
    },
    [form]
  );

  const recaptchaExpire = useCallback(() => {
    form.setValue('recaptcha', null);
  }, [form]);

  if (forgotPassword) {
    return <Redirect to={{ pathname: LOGIN.PASSWORD_RECOVERY }} push />;
  }

  const companies = ssoLookupDetails?.companies ?? [];
  if (companies.length === 1 && companies[0].sso_required && !ssoLookupDetails.forProvisioning) {
    redirectToSso(companies[0]);
  }

  const loginButtonProps = { to: LOGIN.SSO_LOOKUP };

  if (companies.length === 1) {
    delete loginButtonProps.to;
    loginButtonProps.onClick = () => redirectToSso(companies[0]);
  } else if (form.getValue('user_email')) {
    loginButtonProps.to += `?email=${form.getValue('user_email')}`;
  }

  return (
    <form>
      {form.error && (
        <Callout title="Login Failed" intent="danger" icon="error" my={2}>
          {form.error}
        </Callout>
      )}
      <FieldLabel id="email-label" fontWeight={theme?.fontWeights.bold}>
        Email address
      </FieldLabel>
      <Field
        disabled={authenticated || authenticating}
        name="user_email"
        autoFocus
        showLabel={false}
        showRequired={false}
        large
        onChange={onEmailChange}
        valid
      >
        <InputGroup
          ref={ref}
          aria-labelledby="email-label"
          autoFocus
          autoComplete="username"
          className="jumbo"
          onBlur={() => onEmailChange(form.getField('user_email'))}
        />
      </Field>

      <Flex lineHeight="14px" alignItems="center" justifyContent="space-between">
        <FieldLabel id="password-label" fontWeight={theme?.fontWeights.bold}>
          Password
        </FieldLabel>
        <Button minHeight="16px" intent="primary" minimal onClick={() => setForgotPassword(true)}>
          Forgot your password?
        </Button>
      </Flex>
      <Field disabled={authenticated || authenticating} name="password" showLabel={false} showRequired={false} large>
        <PasswordInput aria-labelledby="password-label" showCapsLock showVisibleToggle jumbo />
      </Field>

      {showRecaptcha && (
        <Box mb={1}>
          <Recaptcha
            sitekey={openConfig.recaptchaSiteKey}
            callback={recaptchaCallback}
            expiredCallback={recaptchaExpire}
          />
        </Box>
      )}
      <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
        <Button
          disabled={!openConfig || !form.dirty || !form.valid}
          intent="primary"
          large
          fill
          loading={authenticated || authenticating}
          onClick={() => form.submit(handleSubmitWrapper)}
          text="Sign in with Email"
          mb={2}
        />
        {openConfig && !openConfig.subtenancy && (
          <>
            <ContentfulDivider mb={2}>
              <Text muted>or</Text>
            </ContentfulDivider>

            <LinkButton large fill {...loginButtonProps}>
              <Icon iconSize={20} icon={IoMdKey} pr={1} /> Sign in with SSO
            </LinkButton>
          </>
        )}
        <Box mt={2}>
          <a href="https://www.kentik.com/go/get-started">Sign up for free!</a>
        </Box>
      </Flex>
    </form>
  );
};

export const LoginForm = Form({ fields, options })(observer(LoginFormInternal));
