import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LoginContextProvider } from 'app/views/login/entry/context';
import { LoginFormDiscriminator } from 'app/views/login/entry/LoginFormDiscriminator';

export const LoginEntry = inject(
  '$app',
  '$auth'
)(
  observer(({ $app, $auth }) => {
    const history = useHistory();
    const location = useLocation();
    const ssoCompanyIdentifier = useParams()?.companyIdentifier;
    const { subtenancy } = $auth.openConfig;

    useEffect(() => {
      // If already authenticated, redirect to /
      if ($auth.authenticated) {
        history.replace('/');
        return;
      }

      $app.setPageTitle('Login');
      sessionStorage.removeItem('UsedSSOLogin');

      if (ssoCompanyIdentifier && location.search.includes('autologin=true')) {
        window.location = `/sso/${ssoCompanyIdentifier}`;
      }
      // this effect runs only once, when login route is initialized
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <LoginContextProvider ssoCompanyIdentifier={ssoCompanyIdentifier} subtenancy={subtenancy}>
        <LoginFormDiscriminator />
      </LoginContextProvider>
    );
  })
);
