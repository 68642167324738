import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { LoginBackground } from 'app/views/login/components/LoginBackground';
import { LoginContentWrapper } from 'app/views/login/LoginContentWrapper';
import { observer } from 'mobx-react';

const LoginWrapperBase = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
`;

const LoginWrapperContentContainer = styled.div`
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`;

/**
 * Wraps the login experience page, establishing the background and content areas for login
 */
export const LoginWrapper = observer(({ children }) => {
  const [pos, setPos] = useState([0, 0]);
  // track user's mouse pointer
  const onMouseMove = useCallback((event) => {
    setPos([event.clientX, event.clientY]);
  }, []);

  return (
    <LoginWrapperBase onMouseMove={onMouseMove}>
      <LoginBackground pos={pos} />
      <LoginWrapperContentContainer>
        <LoginContentWrapper>{children}</LoginContentWrapper>
      </LoginWrapperContentContainer>
    </LoginWrapperBase>
  );
});
