import { EMAIL_REGEX } from '@kentik/ui-shared/util/regex';

export default {
  message: 'Invalid email address',
  fn: (value) => {
    if (value) {
      if (Array.isArray(value)) {
        return value.every((val) => val && val.match(EMAIL_REGEX));
      }
      return value.match(EMAIL_REGEX);
    }
    return value;
  }
};
