import { VerifyTwoFactor } from 'app/views/login/2fa';
import ProtectedRoute from 'app/components/ProtectedRoute';
import { LoginWrapper } from 'app/views/login/LoginWrapper';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import { LoginEntry } from 'app/views/login/entry';
// something weird going on with this import...
import { SsoLookup } from 'app/views/login/ssoLookup/index';
import { observer } from 'mobx-react';
import { PasswordForm } from 'app/views/login/password';

/**
 * Provides all routes which live under /login, wrapped inside a shared Login container. This is necessary because
 * otherwise the background would be inconsistent during state transitions.
 */
export const LoginRoutes = observer(() => (
  <LoginWrapper>
    <Switch>
      <Route path={LOGIN.SSO_COMPANY} component={LoginEntry} />
      <ProtectedRoute exact skip2fa path={LOGIN.TWO_FACTOR} component={VerifyTwoFactor} />
      <Route exact path={LOGIN.SSO_LOOKUP} component={SsoLookup} />
      <Route exact path={LOGIN.PASSWORD_RECOVERY} component={PasswordForm} />
      <Route exact path={LOGIN.ACCOUNT_ACTIVATION} render={() => <PasswordForm accountActivation />} />
      <Route path={LOGIN.ENTRY} component={LoginEntry} />
    </Switch>
  </LoginWrapper>
));
