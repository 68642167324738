import React from 'react';
import { snakeCase } from 'lodash';
import AwsLogo from 'app/components/AwsLogo';
import AzureLogo from 'app/components/AzureLogo';
import GoogleLogo from 'app/components/GoogleLogo';
import OracleLogo from 'app/components/OracleLogo';
import IbmLogo from 'app/components/IbmLogo';

import { ReactComponent as AlibabaCloudSvgLogo } from 'app/assets/logos/alibaba-cloud.svg';

const AlibabaCloudLogo = ({ iconSize, ...props }) => <AlibabaCloudSvgLogo {...props} />;

// Note: options get added by $dictionary.initializeOptions()
export const ALERT_DIMENSIONS_WHITELIST = new Set([]);
export const ALERT_SYSLOG_DIMENSIONS_WHITELIST = new Set([]);
export const ALERT_SNMP_TRAP_DIMENSIONS_WHITELIST = new Set([]);

export const ALERT_METRIC_SPECIAL_DEVICE_LABELS = {
  a10_cgn: 'A10 CGN',
  cisco_asa: 'ASA',
  cisco_zone_based_firewall: 'Cisco ZFW',
  istio: 'Istio',
  kappa: 'Kappa',
  silverpeak: 'Silverpeak',
  meraki: 'Meraki',
  versa: 'Versa'
};

// Note: additional options get added by $dictionary.initializeOptions()
export const ALERT_METRIC_OPTIONS = [
  //   value - alert-api policy metric
  //   label - user-friendly text
  //   unit - unit used in Data Explorer queries
  {
    value: 'bits',
    label: 'Bits/s',
    unit: 'bytes'
  },
  {
    value: 'bits_in',
    label: 'Inbound Bits/s',
    unit: 'in_bytes'
  },
  {
    value: 'bits_out',
    label: 'Outbound Bits/s',
    unit: 'out_bytes'
  },
  {
    value: 'fpex_latency',
    unit: 'fpex_latency_ms',
    label: 'First Payload Exchange Latency (ms)'
  },
  {
    value: 'packets',
    label: 'Packets/s',
    unit: 'packets'
  },
  {
    value: 'packets_in',
    label: 'Inbound Packets/s',
    unit: 'in_packets'
  },
  {
    value: 'retransmits_in',
    label: 'Retransmits/s ',
    unit: 'retransmits_out'
  },
  {
    value: 'perc_retransmits_in',
    label: '% Retransmits',
    unit: 'perc_retransmits_out'
  },
  {
    value: 'packets_out',
    label: 'Outbound Packets/s',
    unit: 'out_packets'
  },
  {
    value: 'unique_src_ip',
    label: 'Unique Source IPs',
    unit: 'unique_src_ip'
  },
  {
    value: 'unique_dst_ip',
    label: 'Unique Destination IPs',
    unit: 'unique_dst_ip'
  },
  {
    value: 'unique_src_port',
    label: 'Unique Source Ports',
    unit: 'unique_src_port'
  },
  {
    value: 'unique_dst_port',
    label: 'Unique Destination Ports',
    unit: 'unique_dst_port'
  },
  {
    value: 'unique_src_geo',
    label: 'Unique Source Countries',
    unit: 'unique_src_geo'
  },
  {
    value: 'unique_dst_geo',
    label: 'Unique Destination Countries',
    unit: 'unique_dst_geo'
  },
  {
    value: 'unique_src_region',
    label: 'Unique Source Region',
    unit: 'unique_src_geo_region'
  },
  {
    value: 'unique_dst_region',
    label: 'Unique Destination Region',
    unit: 'unique_dst_geo_region'
  },
  {
    value: 'unique_src_city',
    label: 'Unique Source City',
    unit: 'unique_src_geo_city'
  },
  {
    value: 'unique_dst_city',
    label: 'Unique Destination City',
    unit: 'unique_dst_geo_city'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__STR03',
    value: 'ktappprotocol__ktrac_bgp_updates__STR03',
    label: 'Route Updates: Vantage Point'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__STR00',
    value: 'ktappprotocol__ktrac_bgp_updates__STR00',
    label: 'Route Updates: Dataset'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__STR01',
    value: 'ktappprotocol__ktrac_bgp_updates__STR01',
    label: 'Route Updates: Collector'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT01',
    value: 'ktappprotocol__ktrac_bgp_updates__INT01',
    label: 'Route Updates: Peer AS'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INET_00',
    value: 'ktappprotocol__ktrac_bgp_updates__INET_00',
    label: 'Route Updates: Peer IP'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT02',
    value: 'ktappprotocol__ktrac_bgp_updates__INT02',
    label: 'Route Updates: AFI'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT03',
    value: 'ktappprotocol__ktrac_bgp_updates__INT03',
    label: 'Route Updates: SAFI'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT04',
    value: 'ktappprotocol__ktrac_bgp_updates__INT04',
    label: 'Route Updates: Update Type'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INET_01',
    value: 'ktappprotocol__ktrac_bgp_updates__INET_01',
    label: 'Route Updates: Route Prefix'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT05',
    value: 'ktappprotocol__ktrac_bgp_updates__INT05',
    label: 'Route Updates: Prefix Length'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INET_02',
    value: 'ktappprotocol__ktrac_bgp_updates__INET_02',
    label: 'Route Updates: Next Hop IP'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__STR05',
    value: 'ktappprotocol__ktrac_bgp_updates__STR05',
    label: 'Route Updates: Communities'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__STR06',
    value: 'ktappprotocol__ktrac_bgp_updates__STR06',
    label: 'Route Updates: Extended Communities'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__src_bgp_aspath',
    value: 'ktappprotocol__ktrac_bgp_updates__src_bgp_aspath',
    label: 'Route Updates: AS Path'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT07',
    value: 'ktappprotocol__ktrac_bgp_updates__INT07',
    label: 'Route Updates: AS Path Length'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__dst_bgp_aspath',
    value: 'ktappprotocol__ktrac_bgp_updates__dst_bgp_aspath',
    label: 'Route Updates: AS Path (Cleaned)'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__INT08',
    value: 'ktappprotocol__ktrac_bgp_updates__INT08',
    label: 'Route Updates: AS Path Length (Cleaned)'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__dst_as',
    value: 'ktappprotocol__ktrac_bgp_updates__dst_as',
    label: 'Route Updates: AS Path Hop Number: R0 (Origin ASN)'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__dst_nexthop_as',
    value: 'ktappprotocol__ktrac_bgp_updates__dst_nexthop_as',
    label: 'Route Updates: AS Path Hop Number: R1'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__dst_second_asn',
    value: 'ktappprotocol__ktrac_bgp_updates__dst_second_asn',
    label: 'Route Updates: AS Path Hop Number: R2'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__dst_third_asn',
    value: 'ktappprotocol__ktrac_bgp_updates__dst_third_asn',
    label: 'Route Updates: AS Path Hop Number: R3'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__src_as',
    value: 'ktappprotocol__ktrac_bgp_updates__src_as',
    label: 'Route Updates: AS Path Hop Number: L0'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__src_nexthop_as',
    value: 'ktappprotocol__ktrac_bgp_updates__src_nexthop_as',
    label: 'Route Updates: AS Path Hop Number: L1'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__src_second_asn',
    value: 'ktappprotocol__ktrac_bgp_updates__src_second_asn',
    label: 'Route Updates: AS Path Hop Number: L2'
  },
  {
    unit: 'unique_ktappprotocol__ktrac_bgp_updates__src_third_asn',
    value: 'ktappprotocol__ktrac_bgp_updates__src_third_asn',
    label: 'Route Updates: AS Path Hop Number: L3'
  }
];

export const AWS_CRITICAL_APIS = [
  'ec2:describeVpcs',
  'ec2:describeSubnets',
  'ec2:describeInstances',
  'ec2:describeInternetGateways',
  'ec2:describeAvailabilityZones',
  'ec2:describeNetworkInterfaces'
];

export const CLOUD_PROVIDERS = {
  OCI: {
    // we use id as empty stream to pull universal plans from $setup.cloudPlanOptions
    id: 'oci',
    code: 'OCI',
    color: '#EA4435',
    name: 'Oracle Cloud Infrastructure (OCI)',
    logo: <OracleLogo />,
    logoCmp: OracleLogo
  },
  AWS: {
    id: 'aws',
    code: 'AWS',
    color: '#FF9400',
    name: 'Amazon Web Services (AWS)',
    logo: <AwsLogo />,
    logoCmp: AwsLogo
  },
  GCE: {
    id: 'gce',
    code: 'GCP',
    color: '#EA4435',
    name: 'Google Cloud Platform (GCP)',
    logo: <GoogleLogo />,
    logoCmp: GoogleLogo
  },
  GCP: {
    id: 'gcp',
    code: 'GCP',
    color: '#EA4435',
    name: 'Google Cloud Platform (GCP)',
    logo: <GoogleLogo />,
    logoCmp: GoogleLogo
  },
  AZURE: {
    id: 'azure',
    code: 'Azure',
    color: '#0072C6',
    name: 'Microsoft Azure',
    logo: <AzureLogo />,
    logoCmp: AzureLogo
  },
  UNIVERSAL: { id: 'universal', code: 'Universal', color: '#FF9400', name: 'Universal' },
  ALIBABA: {
    id: 'alibaba',
    code: 'Alibaba',
    name: 'Alibaba',
    logo: <AlibabaCloudLogo />,
    logoCmp: AlibabaCloudLogo
  },
  IBM: { id: 'ibm', code: 'IBM', name: 'IBM', color: '#089FEF', logo: <IbmLogo />, logoCmp: IbmLogo },
  byId(id) {
    return (id && this[id.toUpperCase()]) || {};
  }
};

export const MAX_DETAIL_TABS = 12;

export const CLOUD_SUB_TYPES = {
  aws_subnet: { id: 'aws', code: 'AWS', name: 'Amazon Web Services (AWS)', logo: <AwsLogo /> },
  gcp_subnet: { id: 'gce', code: 'GCP', name: 'Google Cloud Platform (GCP)', logo: <GoogleLogo /> },
  azure_subnet: { id: 'azure', code: 'Azure', name: 'Microsoft Azure', logo: <AzureLogo /> },
  ibm_subnet: { id: 'ibm', code: 'IBM', name: 'IBM Cloud', logo: <IbmLogo /> }
};

export const ADDON_TYPES = {
  flowPak: { type: 'flowpak', name: 'FlowPak' },
  cloudPak: { type: 'cloudpak', name: 'CloudPak' },
  customDimension: { type: 'customdimension', name: 'Custom Dimension' },
  fastRetention: { type: 'fastretention', name: 'Traffic Retention (fast)' },
  fullRetention: { type: 'fullretention', name: 'Forensic Retention (full)' }
};

export const ADDON_TYPE_NAME_MAP = Object.keys(ADDON_TYPES).reduce((acc, typeKey) => {
  acc[ADDON_TYPES[typeKey].type] = ADDON_TYPES[typeKey].name;
  return acc;
}, {});

export const PLAN_TYPES = {
  core: { code: 'core', name: 'Core Devices' },
  edge: { code: 'edge', name: 'Edge Devices' },
  cloud: { code: 'cloud', name: 'Cloudpak' },
  flowpak: { code: 'flowpak', name: 'Flowpak' },
  legacy: { code: 'legacy', name: 'Legacy' },
  metrics: { code: 'metrics', name: 'Metrics' },
  ksynth: { code: 'ksynth', name: 'Synthetics' },
  universalpak: { code: 'universalpak', name: 'Universalpak' }
};

export const PLAN_SIZES = {
  '1GB': { code: '1GB', name: 'Up to 1 GB' },
  '10GB': { code: '10GB', name: 'Up to 10 GB' },
  '100GB': { code: '100GB', name: 'Up to 100 GB' },
  '1000GB': { code: '1000GB', name: 'Up to 1 TB' }
};

export const PERCENT_METRICS = [
  'perc_retransmits_in',
  'perc_retransmits_out',
  'retransmits_in',
  'retransmits_out',
  'perc_out_of_order_in',
  'perc_fragments',
  'inUtilization',
  'outUtilization'
];

// Metrics whose magnitude can be represented with Greek letters
export const GREEK_METRICS = ['bits', 'packets'];

export const GREEK_FACTORS = [
  { value: 10 ** 0, label: '' },
  { value: 10 ** 3, label: 'K' },
  { value: 10 ** 6, label: 'M' },
  { value: 10 ** 9, label: 'G' },
  { value: 10 ** 12, label: 'T' },
  { value: 10 ** 15, label: 'P' }
];

export const privateIpOptions = [
  {
    value: '0.0.0.0/8',
    label: '0.0.0.0/8',
    description: 'Default route, broadcast to the current (“this”)'
  },
  {
    value: '127.0.0.0/8',
    label: '127.0.0.0/8',
    description: '127.0.0.1 loopback net'
  },
  {
    value: '100.64.0.0/10',
    label: '100.64.0.0/10',
    description: 'CGN (Carrier Grade NAT) reserved range'
  },
  {
    value: '10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16',
    label: '10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16',
    description: 'RFC 1918 IPv4 Private Networks'
  },
  {
    value: '169.254.0.0/16',
    label: '169.254.0.0/16',
    description: 'APIPA (auto-ip address for failed DHCP requests)'
  },
  {
    value: '192.0.0.0/24',
    label: '192.0.0.0/24',
    description: 'IANA special purpose IPv4 space'
  },
  {
    value: '192.0.2.0/24',
    label: '192.0.2.0/24',
    description: 'TEST-NET for use in documentation'
  },
  {
    value: '198.18.0.0/15',
    label: '198.18.0.0/15',
    description: 'Private Network - reserved for testing'
  },
  {
    value: '198.51.100.0/24',
    label: '198.51.100.0/24',
    description: 'TEST-NET-2 for use in documentation'
  },
  {
    value: '203.0.113.0/24',
    label: '203.0.113.0/24',
    description: 'TEST-NET-3 for use in documentation'
  },
  {
    value: '224.0.0.0/4',
    label: '224.0.0.0/4',
    description: 'Reserved for Multicast'
  },
  {
    value: '192.88.99.0/24',
    label: '192.88.99.0/24',
    description: 'Reserved for 6to4 anycast relays'
  },
  {
    value: '240.0.0.0/4',
    label: '240.0.0.0/4',
    description: 'Reserved for future use'
  },
  {
    value: 'fc00::/7',
    label: 'fc00::/7',
    description: 'RFC 4193 IPv6 Unique Local Address (ULA)'
  },
  {
    value: 'fe80::/10',
    label: 'fe80::/10',
    description: 'IPv6 link-local Address'
  }
];

export const RPKI_OPTIONS = [
  {
    label: 'RPKI Unknown',
    value: 'RPKI Unknown'
  },
  {
    label: 'RPKI Valid',
    value: 'RPKI Valid'
  },
  {
    label: 'RPKI Invalid - covering Valid/Unknown',
    value: 'RPKI Invalid - covering Valid/Unknown'
  },
  {
    label: 'RPKI Invalid - Will be dropped',
    value: 'RPKI Invalid - Will be dropped'
  }
];

export const TEST_TYPES = {
  IP_ADDRESS: 'ip-address',
  NETWORK_GRID: 'network-grid',
  PAGE_LOAD: 'page-load',
  HOSTNAME: 'hostname',
  URL: 'url',
  AGENT: 'agent',
  DNS: 'dns',
  DNS_GRID: 'dns-grid',
  APPLICATION_MESH: 'application-mesh',
  ASN: 'asn',
  CDN: 'cdn',
  COUNTRY: 'country',
  REGION: 'region',
  CITY: 'city',
  BGP_MONITOR: 'bgp-monitor',
  TRANSACTION: 'transaction'
};

export const TASK_TYPES = {
  HTTP: 'http',
  KNOCK: 'knock',
  DNS: 'dns',
  PAGE_LOAD: 'page-load',
  PING: 'ping',
  TRACE_ROUTE: 'traceroute',
  TRANSACTION: 'transaction',
  TIMEOUT: 'timeout'
};

// Acceptable Test Freqencies in seconds
export const TEST_INTERVAL_SECS = [1, 15, 60, 120, 300, 600, 900, 1800, 3600, 5400];

export const agentMatrixTestTypes = [TEST_TYPES.APPLICATION_MESH];
export const userDefinedTestTypes = [
  TEST_TYPES.IP_ADDRESS,
  TEST_TYPES.NETWORK_GRID,
  TEST_TYPES.HOSTNAME,
  TEST_TYPES.AGENT
];
export const flowBasedTestTypes = [
  TEST_TYPES.ASN,
  TEST_TYPES.CDN,
  TEST_TYPES.COUNTRY,
  TEST_TYPES.REGION,
  TEST_TYPES.CITY
];
export const urlDefinedTestTypes = [TEST_TYPES.URL, TEST_TYPES.PAGE_LOAD, TEST_TYPES.TRANSACTION];
export const dnsTestTypes = [TEST_TYPES.DNS, TEST_TYPES.DNS_GRID];
export const hostnameTestTypes = [TEST_TYPES.HOSTNAME];

export const urlDefinedTaskTypes = [TASK_TYPES.HTTP, TASK_TYPES.PAGE_LOAD, TASK_TYPES.TRANSACTION];

export const SYNTH_LOOKBACK_OPTIONS = [
  { value: 300, label: 'Last 5 Minutes' },
  { value: 900, label: 'Last 15 Minutes' },
  { value: 3600, label: 'Last Hour' },
  { value: 10800, label: 'Last 3 Hours' },
  { value: 21600, label: 'Last 6 Hours' },
  { value: 86400, label: 'Last 1 Day' },
  { value: 172800, label: 'Last 2 Days' },
  { value: 259200, label: 'Last 3 Days' },
  { value: 604800, label: 'Last Week' },
  { value: 1209600, label: 'Last 2 Weeks' },
  { value: 2592000, label: 'Last 30 Days' }
];

export const SYNTH_TRACE_PERIOD_DEFAULT = 60;

export const DEFAULT_LOOKBACK = 3600; // seconds
export const DEFAULT_MESH_LOOKBACK = 900; // 15m in seconds
export const DEFAULT_MIN_LOOKBACK = 300; // seconds
export const FIVE_MINUTE_PERIOD = 300; // 5m * 60s
export const FIVE_MINUTE_LOOKBACK = 10800; // 3h * 60m * 60s, for tests with a 5m ping period
export const SYNGEST_CUTOVER = new Date('Sep 1 2023').valueOf() / 1000;

// used to determine min lookbacks based on test frequency
export const MIN_LOOKBACK_BY_TEST_FREQUENCY = [
  { testFrequency: 86400, minLookbackSeconds: 604800 }, // 7d min lookback on test frequency >= 24h
  { testFrequency: 3600, minLookbackSeconds: 10800 }, // 3h min lookback on test frequency >= 1h
  { testFrequency: 1800, minLookbackSeconds: 3600 }, // 1h min lookback on test frequency >= 30m
  { testFrequency: 900, minLookbackSeconds: 3600 }, // 1h min lookback on test frequency >= 15m
  { testFrequency: 600, minLookbackSeconds: 3600 }, // 1h min lookback on test frequency >= 10m
  { testFrequency: 300, minLookbackSeconds: 900 } // 15m min lookback on test frequency >= 5m
];

export const SITE_TYPES = {
  dataCenter: 'Data Center',
  cloud: 'Cloud',
  branch: 'Branch/Building',
  connectivity: 'Connectivity PoP',
  customer: 'Customer/Partner',
  other: 'Other'
};

export const DASHBOARD_MODULES = new Set(['Botnet & Threat Analysis', 'RPKI Analysis']);

export const KMI_RANK_TYPES = {
  CUSTOMER_BASE: {
    rank_type: 0,
    value: 0,
    label: 'Customer Base: All',
    labelShort: 'All',
    id: 'customerBase'
  },
  CUSTOMER_BASE_RETAIL: {
    rank_type: 1,
    value: 1,
    label: 'Customer Base: Retail',
    labelShort: 'Retail',
    id: 'customerBaseRetail'
  },
  CUSTOMER_BASE_WHOLESALE: {
    rank_type: 2,
    value: 2,
    label: 'Customer Base: Wholesale',
    labelShort: 'Wholesale',
    id: 'customerBaseWholesale'
  },
  CUSTOMER_BASE_BACKBONE: {
    rank_type: 4,
    value: 4,
    label: 'Customer Base: Backbone',
    labelShort: 'Backbone',
    id: 'customerBaseBackbone'
  },
  CUSTOMER_GROWTH: {
    rank_type: 10,
    value: 10,
    label: 'Customer Growth',
    id: 'customerGrowth'
  },
  PEERING: {
    rank_type: 20,
    value: 20,
    label: 'Peering',
    id: 'peering'
  },

  byId(id) {
    return (id && this[snakeCase(id).toUpperCase()]) || {};
  },

  byRankType(rankType) {
    return Number.isInteger(rankType) ? Object.values(this).find((rank) => rank.rank_type === rankType) : {};
  },

  asArray() {
    return Object.values(this).filter((rank) => !!rank.label);
  }
};

export const DNS_RECORD_TYPES = {
  1: 'A',
  2: 'NS',
  3: 'MD',
  4: 'MF',
  5: 'CNAME',
  6: 'SOA',
  7: 'MB',
  8: 'MG',
  9: 'MR',
  10: 'NULL',
  11: 'WKS',
  12: 'PTR',
  13: 'HINFO',
  14: 'MINFO',
  15: 'MX',
  16: 'TXT',
  17: 'RP',
  18: 'AFSDB',
  19: 'X25',
  20: 'ISDN',
  21: 'RT',
  22: 'NSAP',
  23: 'NSAP-PTR',
  24: 'SIG',
  25: 'KEY',
  26: 'PX',
  27: 'GPOS',
  28: 'AAAA',
  29: 'LOC',
  30: 'NXT',
  31: 'EID',
  32: 'NIMLOC',
  33: 'SRV',
  34: 'ATMA',
  35: 'NAPTR',
  36: 'KX',
  37: 'CERT',
  38: 'A6',
  39: 'DNAME',
  40: 'SINK',
  41: 'OPT',
  42: 'APL',
  43: 'DS',
  44: 'SSHFP',
  45: 'IPSECKEY',
  46: 'RRSIG',
  47: 'NSEC',
  48: 'DNSKEY',
  49: 'DHCID',
  50: 'NSEC3',
  51: 'NSEC3PARAM',
  52: 'TLSA',
  53: 'SMIMEA',
  54: 'Unassigned',
  55: 'HIP',
  56: 'NINFO',
  57: 'RKEY',
  58: 'TALINK',
  59: 'CDS',
  60: 'CDNSKEY',
  61: 'OPENPGPKEY',
  62: 'CSYNC',
  63: 'ZONEMD',
  64: 'SVCB',
  65: 'HTTPS',
  99: 'SPF',
  100: 'UINFO',
  101: 'UID',
  102: 'GID',
  103: 'UNSPEC',
  104: 'NID',
  105: 'L32',
  106: 'L64',
  107: 'LP',
  108: 'EUI48',
  109: 'EUI64',
  249: 'TKEY',
  250: 'TSIG',
  251: 'IXFR',
  252: 'AXFR',
  253: 'MAILB',
  254: 'MAILA',
  255: '*',
  256: 'URI',
  257: 'CAA',
  258: 'AVC',
  259: 'DOA',
  260: 'AMTRELAY',
  32768: 'TA',
  32769: 'DLV'
};

export const KMI_INSIGHT_TYPES = {
  1: { label: 'Market Entrance', value: 1 },
  2: { label: 'Market Exit', value: 2 },
  3: { label: 'Ranking Gain', value: 3 },
  4: { label: 'Ranking Loss', value: 4 },
  11: { label: 'Start Prefix Origination', value: 11 },
  12: { label: 'Stop Prefix Origination', value: 12 },
  13: { label: 'Increase Prefix Origination', value: 13 },
  14: { label: 'Decrease Prefix Origination', value: 14 },
  101: { label: 'Customer Gain', value: 101 },
  102: { label: 'Customer Loss', value: 102 },
  103: { label: 'Customer Routing Gain', value: 103 },
  104: { label: 'Customer Routing Loss', value: 104 },
  201: { label: 'Provider Gain', value: 201 },
  202: { label: 'Provider Loss', value: 202 },
  203: { label: 'Provider Routing Gain', value: 203 },
  204: { label: 'Provider Routing Loss', value: 204 }
};

export const DEFAULT_EXPIRED_PASSWORD_DURATION = 90 * 24 * 60 * 60 * 1000; // 90 days

export const CLOUD_EXPORT_ROUTE = '/cloudexport';
