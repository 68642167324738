import React, { Fragment } from 'react';
import Callout from 'core/components/Callout';
import Box from 'core/components/Box';
import PoweredByKentik from 'app/components/PoweredByKentik';
import { observer } from 'mobx-react';
import $auth from 'app/stores/$auth';

const LoginDifficulties = () => (
  <Fragment>
    <Callout title="Trouble logging in?" mt={2} p={2}>
      <Box>To log in, you must register via the email we sent you.</Box>
      <Box>
        <a href="mailto:support@kentik.com">Contact our support team.</a>
      </Box>
    </Callout>
  </Fragment>
);

const LoginDifficultiesWithSubtenancy = observer(() => {
  const { subtenancy } = $auth.openConfig;
  return (
    <Fragment>
      <Callout title="Trouble logging in?" mt={2} p={2}>
        {subtenancy.config.supportURL && (
          <div className="pt-text-muted">
            <a href={subtenancy.config.supportURL}>Visit our support page.</a>
          </div>
        )}
        <div className="pt-text-muted">
          <a href={`mailto:${subtenancy.config.supportEmail || 'support@kentik.com'}`}>Contact our support team.</a>
        </div>
      </Callout>

      {!subtenancy.permissions.advancedMode && (
        <Box mt={1}>
          <PoweredByKentik blackText />
        </Box>
      )}
    </Fragment>
  );
});

export const LoginDifficultiesCallout = () => {
  const { subtenancy } = $auth.openConfig;
  return subtenancy ? <LoginDifficultiesWithSubtenancy /> : <LoginDifficulties />;
};
