import styled from 'styled-components';
import { space } from 'styled-system';
import React from 'react';
import { Box } from 'core/components/index';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  ${space}
`;

const InlineDivider = styled.div`
  flex: 1;
  border-top: 1px solid #ccc;
`;

export const ContentfulDivider = ({ children, mb }) => (
  <DividerContainer mb={mb}>
    <InlineDivider />
    <Box px={1}>{children}</Box>
    <InlineDivider />
  </DividerContainer>
);
