import { NMS_POLICY_CONDITION_OPERATORS } from '@kentik/ui-shared/nms/policies';

/**
 * This file contains UI-specific constants that are used to help render the form, as well as to serialize form data.
 * These are types that are distinct from data available to us through the API, and are not shared with API-provided types.
 */

export const CLEAR_CONDITION_TYPES = {
  CONDITIONAL: 'conditional',
  CLEAR_MANUAL: 'clear_manual',
  CLEAR_WHEN_NOT_ACTIVATED: 'clear_when_not_activated'
};

export const CONDITION_GROUP_CONNECTOR_TYPES = {
  ANY: 'any',
  ALL: 'all',
  NONE: 'none'
};

export const CONDITION_ROOT_CONNECTOR_TYPES = {
  ANY: CONDITION_GROUP_CONNECTOR_TYPES.ANY,
  ALL: CONDITION_GROUP_CONNECTOR_TYPES.ALL
};

export const CONDITION_OPERATOR_THRESHOLD_TYPES = NMS_POLICY_CONDITION_OPERATORS;

export const CONDITION_OPERATOR_SINGLE_STATE_TYPES = {
  IS: 'is',
  IS_NOT: 'is-not'
};

export const CONDITION_OPERATOR_STATE_CHANGE_TYPES = {
  WAS: 'was',
  WAS_NOT: 'was-not'
};

export const enumValuesContain = (myEnum, value) => Object.values(myEnum).includes(value);

export const CONDITION_TYPE = {
  THRESHOLD: 'threshold',
  STATE_CHANGE: 'state-change',
  STATE_IN: 'state-in'
};

export const TARGET_TYPES = {
  ENTITY: 'entity',
  EVENT: 'event',
  CUSTOM: 'custom'
};

export const MAX_CONDITION_GROUP_DEPTH = 3;
