const options = {
  name: 'login',
  suppressFormValues: true
};

const fields = {
  user_email: {
    label: 'Email address',
    rules: 'required|email',
    validateDebounceDurationMs: 0
  },
  password: {
    label: 'Password',
    type: 'password',
    rules: 'required',
    validateDebounceDurationMs: 0
  },
  recaptcha: {}
};

export { fields, options };
