import React, { Component } from 'react';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import SplitPane from 'react-split-pane';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { FiShare2 } from 'react-icons/fi';

import { Box, Button, Card, Flex, FlexColumn, Icon, Menu, Popover, Text } from 'core/components';
import { FormComponent } from 'core/form';
import storeLoader from 'app/stores/storeLoader';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import Legend from 'app/components/dataviews/views/legend/Legend';
import { OptionsMenu, RefreshButton } from 'app/components/dataviews/tools';
import Page from 'app/components/page/Page';
import { fields, options } from 'app/forms/config/explorerQuery';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import ExplorerSidebar from 'app/views/core/explorer/ExplorerSidebar';
import ViewSidebar from 'app/views/core/ViewSidebar';
import FavoriteButton from 'app/views/core/FavoriteButton';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import ExportMenuItem from 'app/components/dataviews/tools/options/ExportMenuItem';
import LabelList from 'app/components/labels/LabelList';
import MKPParentLink from './MKPParentLink';
import DescriptionDialog from './DescriptionDialog';
import DescriptionButton from './DescriptionButton';

const splitPaneProps = {
  split: 'horizontal',
  pane2Style: { display: 'flex', overflow: 'auto' },
  defaultSize: 'calc(50vh - 25px)'
};

const savedViewDefaultNames = {
  new: 'New Saved View',
  whenNotFound: 'Saved View'
};

@storeLoader('$savedViews')
@inject('$app', '$auth', '$dataviews', '$recentlyViewed', '$explorer', '$mkp', '$sharedLinks', '$subscriptions')
@withRouter
@HotkeysTarget
@observer
class SavedView extends Component {
  debouncedResize;

  lastWindowHeight;

  splitPane;

  state = {
    savedView: null,
    dataview: null,
    error: '',
    sidebarOpen: false,
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    descriptionDialogOpen: false,
    isSubscribed: undefined,
    selectedModel: undefined
  };

  // There's some shit going down here that I tried to explain
  static getDerivedStateFromProps(props, state) {
    const { $auth, $savedViews, $explorer, $recentlyViewed, match, loading } = props;
    const { viewId, hash, params } = match.params;

    if (loading) {
      return { savedView: null, dataview: null, error: '' };
    }

    if (hash) {
      // This is a safety check to prevent infinite loading of $explorer.loadHash.
      // We check if there's a hash currently trying to be loaded don't try to load it again
      // We only put a dataview on the state when we are loading a hash.
      if (state.savedView && (state.dataview.loading || (state.dataview.hash && hash === state.dataview.hash))) {
        return null;
      }
      $explorer.loadHash(hash, false);

      const savedView = viewId
        ? $savedViews.collection.get(parseInt(viewId))
        : $savedViews.collection.forge({
            user_id: Number($auth.getActiveUserProperty('id'))
          });

      if (!savedView) {
        return { savedView, dataview: null, error: 'No matching view found' };
      }

      return {
        savedView,
        dataview: $explorer.dataview,
        sidebarOpen: 'query',
        error: ''
      };
    }

    if (!viewId) {
      return { savedView: null, dataview: null, error: 'No saved view id provided.' };
    }

    const savedViewId = parseInt(viewId);
    const savedView = $savedViews.collection.get(savedViewId);

    if (!savedView) {
      return { savedView, dataview: null, error: 'No matching view found' };
    }

    // This is the case for initial loading an existing saved view
    if (
      savedView !== state.savedView ||
      (state.savedView &&
        !hash &&
        !state.dataview.loading &&
        $explorer.dataview.hash !== state.savedView.get('saved_query_id'))
    ) {
      let overrides;
      if (params) {
        overrides = JSON.parse(decodeURIComponent(params));
      }
      $recentlyViewed.add({ view_type: savedView.type, view_id: savedViewId, view_name: savedView.get('view_name') });
      $explorer.loadView(savedView, false, overrides);

      return { savedView, dataview: $explorer.dataview, error: '' };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { $explorer } = props;
    $explorer.clear();
  }

  componentDidMount() {
    const { $app, $mkp, $subscriptions } = this.props;
    if (!$app.isSubtenant) {
      $mkp.tenants.fetch();
      $subscriptions.collection.fetch();
    }
    this.lastWindowHeight = window.innerHeight;
    window.addEventListener('resize', this.handleWindowResize);
    this.initializeHashDisposer();
  }

  initializeHashDisposer() {
    this.hashDisposer = autorun(() => {
      const { dataview, savedView } = this.state;
      const { history, match } = this.props;
      const { hash: matchHash } = match.params;

      if (
        dataview &&
        savedView &&
        dataview.fullyLoaded &&
        matchHash !== dataview.hash &&
        dataview.hash !== savedView.get('saved_query_id')
      ) {
        // We don't want to include a model id in the url if it's not a saved model
        // This will cause issues if someone tries to share the link and we can
        // potentially try looking it up against $savedViews.collection
        if (savedView.isNew) {
          history.push(`/v4/library/saved-views/hash/${dataview.hash}`);
        } else {
          history.push(`/v4/library/saved-views/${savedView.id}/hash/${dataview.hash}`);
        }
      }
    });
  }

  componentWillUnmount() {
    const { $explorer } = this.props;
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.hashDisposer) {
      this.hashDisposer();
    }
    $explorer.destroy(false);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+q"
          label="Toggle Query Drawer"
          onKeyDown={() => this.handleSidebarToggle('query')}
        />
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+v"
          label="Toggle Saved Views Drawer"
          onKeyDown={() => this.handleSidebarToggle('savedViews')}
          preventDefault
        />
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+r"
          label="Refresh Data"
          onKeyDown={this.handleRefreshDataview}
        />
        <Hotkey
          global
          group="Dashboard/Saved View"
          combo="shift+d"
          label="Toggle Description"
          onKeyDown={this.handleToggleDescriptionDialog}
        />
      </Hotkeys>
    );
  }

  handlePaneResize = (size) => {
    const { dataview } = this.state;
    dataview.reflow();
    localStorage.setItem('kentik.explorerPaneRatio', window.innerHeight - size);
  };

  handleWindowResize = () => {
    const { dataview } = this.state;

    if (this.debouncedResize) {
      clearTimeout(this.debouncedResize);
    }

    this.debouncedResize = setTimeout(() => {
      if (!this.splitPane) {
        dataview.reflow();
        return;
      }

      const dvHeight = this.splitPane.pane1.state ? this.splitPane.pane1.state.size : '';
      const newWindowHeight = window.innerHeight;
      if (parseInt(dvHeight, 10)) {
        this.splitPane.setSize(
          { size: Math.max(this.splitPane.props.minSize, dvHeight - (this.lastWindowHeight - newWindowHeight)) },
          this.splitPane.state
        );
      }
      dataview.reflow();
      this.lastWindowHeight = newWindowHeight;
    }, 500);
  };

  handleSidebarToggle = (type) => {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: sidebarOpen === type ? false : type });
  };

  handleSidebarClose = () => {
    this.setState({ sidebarOpen: false });
  };

  handleSave = (savedViewFormValues) => {
    const { history, $savedViews } = this.props;
    const { savedView, dataview } = this.state;
    const { queryBuckets } = dataview;
    const { is_new, ...savedViewAttributes } = savedViewFormValues;

    const isNewView = savedView.isNew || is_new;

    return queryBuckets.save({ persist: true }).then((saved_query_id) => {
      const savedViewModel = is_new ? $savedViews.collection.forge() : savedView;

      return savedViewModel.save({ ...savedViewAttributes, saved_query_id }).then(() => {
        if (isNewView) {
          history.push(`/v4/library/saved-views/${savedViewModel.id}`);
        }
        return savedView;
      });
    });
  };

  handleRevert = () => {
    const { $explorer, history } = this.props;
    const { savedView } = this.state;
    $explorer.loadView(savedView, false);

    this.setState(() => ({ savedView, dataview: $explorer.dataview, error: '' }));
    history.push(`/v4/library/saved-views/${savedView.id}`);
  };

  handleRemove = () => {
    const { history } = this.props;
    const { savedView } = this.state;

    return savedView.destroy().then(() => {
      history.push('/v4/library');
    });
  };

  toggleShareDialog = (dialogType) => {
    const { $subscriptions } = this.props;
    const { savedView } = this.state;
    const attributes = { content_type: 'savedview', content_id: savedView.id };

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  onDialogClose = () => {
    this.setState({
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      selectedModel: undefined
    });
    this.setSubscriptionStatus();
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  setSubscriptionStatus = () => {
    const { $auth, $subscriptions, $app } = this.props;
    const { savedView } = this.state;
    if (savedView && !$app.isSubtenant) {
      this.setState({
        isSubscribed: $subscriptions.isUserSubscribedToContent(
          $auth.activeUser.user_email,
          'savedview',
          savedView.get('id')
        )
      });
    }
  };

  splitPaneRef = (ref) => {
    this.splitPane = ref;
  };

  handleToggleDescriptionDialog = () => {
    const { descriptionDialogOpen } = this.state;
    this.setState({ descriptionDialogOpen: !descriptionDialogOpen });
  };

  handleCloseDescriptionDialog = () => {
    this.setState({ descriptionDialogOpen: false });
  };

  render() {
    const { $app, $dataviews, $explorer, $mkp, $savedViews, $sharedLinks } = this.props;
    const {
      savedView,
      dataview,
      error,
      sidebarOpen,
      shareDialogOpen,
      subscribeDialogOpen,
      unsubscribeDialogOpen,
      descriptionDialogOpen,
      selectedModel,
      isSubscribed
    } = this.state;

    if (isSubscribed === undefined) {
      this.setSubscriptionStatus();
    }

    if (!dataview) {
      return (
        <Page
          title={savedView && savedView.isNew ? savedViewDefaultNames.new : savedViewDefaultNames.whenNotFound}
          canFullScreen
        >
          {error}
        </Page>
      );
    }
    const { selectedQuery } = dataview.queryBuckets;
    let sidebarQuery;
    if (selectedQuery) {
      sidebarQuery = ExplorerQueryModel.createFromQueryModel(selectedQuery);
    }

    let explorerChartHeight = 'calc(50vh - 25px)';
    const previousExplorerPaneRatio = localStorage.getItem('kentik.explorerPaneRatio');
    if (previousExplorerPaneRatio) {
      explorerChartHeight = Math.max(window.innerHeight - previousExplorerPaneRatio, 56);
    }

    const viewCfg = $dataviews.getConfig(dataview.viewType);

    const tenantUserCount = $mkp.countSavedViewUsers(savedView.id);
    const confirmText = (
      <FlexColumn>
        {tenantUserCount > 0 && <Text>This view is used by {tenantUserCount} Tenant(s).</Text>}
        <Text> Are you sure you want to remove &ldquo;{savedView.get('view_name')}&rdquo;?</Text>
      </FlexColumn>
    );

    const buttonProps = {
      minimal: true,
      mx: '2px'
    };

    const removeProps = {
      show: savedView.canEdit,
      entityName: 'Saved View',
      model: savedView,
      onRemove: this.handleRemove,
      confirmText
    };

    const subnavTools = (
      <>
        <RefreshButton buttonProps={buttonProps} model={dataview} allowLiveUpdate />
        {!$app.isSubtenant && savedView && !savedView.isNew && (
          <>
            <Button icon={FiShare2} text="Share" minimal onClick={() => this.toggleShareDialog('share')} />
            <OptionsMenu
              buttonProps={buttonProps}
              dataview={dataview}
              showText
              removeProps={removeProps}
              savedViewId={savedView.id}
              hideResetToDefaultQuery
              onSubscribeClick={() => this.toggleShareDialog('subscribe')}
              onUnsubscribeClick={isSubscribed && (() => this.toggleShareDialog('unsubscribe'))}
            />
          </>
        )}
        {$app.isSubtenant && (
          <Popover
            content={
              <Menu>
                <ExportMenuItem dataview={dataview} />
              </Menu>
            }
            position="bottom-right"
          >
            <Button text="Actions" rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />} {...buttonProps} />
          </Popover>
        )}
        <Button
          text="Open"
          icon="folder-open"
          ml="2px"
          active={sidebarOpen === 'savedViews'}
          onClick={() => this.handleSidebarToggle('savedViews')}
          minimal
        />
        <Button
          text="Query"
          icon={AiOutlineMenuFold}
          ml="2px"
          active={sidebarOpen === 'query'}
          onClick={() => this.handleSidebarToggle('query')}
          minimal
        />
      </>
    );

    const dataviewWrapperStyles = {};

    if (selectedQuery && selectedQuery.get('generatorMode')) {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
      dataviewWrapperStyles.bg = 'subnavBackground';
    }

    if (dataview.viewType === 'table') {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
    }

    const dataViewWrapper = (
      <DataViewWrapper
        dataview={dataview}
        fitToHeight
        viewProps={{
          showNativeLegend: false,
          hasFooter: true,
          height: '100%'
        }}
        headerProps={{
          title: (
            <>
              <Flex alignItems="center">
                <FavoriteButton model={savedView} />
                <Box ml="4px">{savedView.get('view_name')}</Box>
                {savedView.isTrending ? (
                  <Flex alignItems="center" justifyContent="center" width={30}>
                    <Icon icon="flame" color="warning" iconSize={16} />
                  </Flex>
                ) : null}
                <DescriptionButton
                  ml={1}
                  hasDescription={savedView.get('view_description')?.length > 0}
                  viewType="saved view"
                  onClick={this.handleToggleDescriptionDialog}
                />
                <LabelList labels={savedView.labels} shareLevel={savedView.shareLevel} fontWeight="normal" />
              </Flex>
            </>
          ),
          showDateRange: true,
          showAppliedFilters: true,
          showDataSources: true,
          showTitleLink: false,
          showViewTypeTag: true,
          shouldArrangeVertically: true,
          showRunCpdFpaButton: true,
          suppressed: $explorer.suppressed
        }}
        ref={this.wrapperRef}
      >
        {({ component, header, footer }) => (
          <>
            <Box pb={2} overflow="hidden">
              {header}
            </Box>
            <Flex flex={1} overflow="auto" {...dataviewWrapperStyles}>
              {component}
            </Flex>
            <Box pt={1}>{footer}</Box>
          </>
        )}
      </DataViewWrapper>
    );

    let parentLinks = [];
    if ($app.isSubtenant) {
      parentLinks = MKPParentLink;
    }

    let drawerContents = null;
    if (sidebarOpen === 'query' && sidebarQuery) {
      drawerContents = (
        <ExplorerSidebar
          model={sidebarQuery}
          width={400}
          savedView={savedView}
          onSave={this.handleSave}
          onRevert={this.handleRevert}
        />
      );
    } else if (sidebarOpen === 'savedViews') {
      drawerContents = (
        <ViewSidebar
          collection={$savedViews.collection}
          fieldMap={{
            title: 'view_name',
            description: 'view_description',
            created_at: 'cdate',
            updated_at: 'edate'
          }}
          labelOptions={$savedViews.labelOptions}
          ownerOptions={$savedViews.ownerOptions}
          privacyOptions={$savedViews.privacyOptions}
          name="Saved View"
          onClose={this.handleSidebarClose}
          toLink={(id) => `/v4/library/saved-views/${id}`}
        />
      );
    }

    return (
      <FormComponent fields={fields} options={options} model={sidebarQuery}>
        <Page
          title={savedView && !savedView.isNew ? savedView.get('view_name') : savedViewDefaultNames.new}
          parentLinks={parentLinks}
          subnavTools={subnavTools}
          drawerContents={drawerContents}
          drawerIsOpen={!!sidebarOpen}
          drawerOnClose={this.handleSidebarClose}
          canFullScreen
          drawerProps={{ showDockedInLargeFullScreen: sidebarOpen === 'query' }}
        >
          <Flex width="100%" height="100%" flex={1} overflow="hidden" flexDirection="column">
            <FlexColumn flex={1} position="relative" overflow="hidden">
              <Flex flexDirection="column" flex={1} position="relative" overflow="hidden">
                {!viewCfg.showLegend && <Flex flex={1}>{dataViewWrapper}</Flex>}

                {viewCfg.showLegend && (
                  <SplitPane
                    onDragFinished={this.handlePaneResize}
                    maxSize={-120}
                    minSize={56}
                    {...splitPaneProps}
                    defaultSize={explorerChartHeight}
                    ref={this.splitPaneRef}
                  >
                    {dataViewWrapper}
                    <Card display="flex" flex={1} m="1px" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                      <Legend dataview={dataview} onChange={$explorer.onFormChange} />
                    </Card>
                  </SplitPane>
                )}
              </Flex>
            </FlexColumn>
          </Flex>

          <ShareViewDialog
            isOpen={shareDialogOpen}
            model={selectedModel}
            dataview={dataview}
            link_type={$sharedLinks.types.savedView}
            defaultName={savedView.get('view_name')}
            defaultDescription={savedView.get('view_description')}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onDialogClose}
          />
          <SubscribeDialog
            isOpen={subscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onDialogClose}
            subscriptionDialogType="subscribe"
          />
          <SubscribeDialog
            isOpen={unsubscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onDialogClose}
            subscriptionDialogType="unsubscribe"
          />
          {savedView.get('view_description')?.length > 0 && (
            <DescriptionDialog
              title="Saved View Description"
              isOpen={descriptionDialogOpen}
              onClose={this.handleCloseDescriptionDialog}
              description={savedView.get('view_description')}
            />
          )}
        </Page>
      </FormComponent>
    );
  }
}

export default SavedView;
