import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import { COMPANY_PLAN_MAP, COMPANY_PLATFORM_MAP, COMPANY_STATUS_MAP } from 'app/stores/sudo/util';

@inject('$sudo')
@observer
export default class CompanyAdminFilterSidebar extends Component {
  get filterTypeHash() {
    return {
      company_status: {
        label: 'Status',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => {
          const status = model.get('company_status');
          return values.includes(status);
        }
      },
      plan: {
        label: 'Plan',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => {
          const planId = model.get('company_plan_id');
          return values.includes(planId);
        }
      },
      platform: {
        label: 'Platform',
        allowMultiSelect: true,
        showCheckboxes: true,
        filter: (model, values) => {
          const platform = model.get('platform');
          return platform ? values.includes(platform) : values.includes('classic');
        }
      }
    };
  }

  render() {
    const { filterTypeHash } = this;

    const filterFields = [
      {
        name: 'company_status',
        options: Object.keys(COMPANY_STATUS_MAP).map((value) => ({ value, label: COMPANY_STATUS_MAP[value] })),
        ...filterTypeHash.company_status
      },
      {
        name: 'compnay_plan_id',
        options: Object.keys(COMPANY_PLAN_MAP).map((value) => ({ value, label: COMPANY_PLAN_MAP[value] })),
        ...filterTypeHash.plan
      },
      {
        name: 'platform',
        options: Object.keys(COMPANY_PLATFORM_MAP).map((value) => ({ value, label: COMPANY_PLATFORM_MAP[value] })),
        ...filterTypeHash.platform
      }
    ];

    return <AdminFilterSidebar {...this.props} filterFields={filterFields} />;
  }
}
