import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useLocation } from 'react-router-dom';

import { Box, Suspense } from 'core/components';

import lazyLoad from 'core/util/lazyLoad';
import PasswordSetRequestForm from 'app/views/login/password/PasswordSetRequestForm';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import $auth from 'app/stores/$auth';

// import to lazy-load as this requires `zxcvbn` which is a huge dependency
const PasswordSetForm = lazyLoad(() => import('app/views/login/password/PasswordSetForm'));

const useGetSearchParam = () => {
  const location = useLocation();

  return useCallback(
    (name) => {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
      const results = regex.exec(location.search);

      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    [location.search]
  );
};

export const PasswordForm = observer(({ accountActivation }) => {
  const getSearchParam = useGetSearchParam();
  const [passwordSetToken, setPasswordSetToken] = useState(getSearchParam('token'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ($auth.isExpiredPassword && !passwordSetToken) {
      setLoading(true);
      $auth
        .requestPasswordReset({ user_email: $auth.getUnderlyingUserProperty('user_email'), skipEmail: true })
        .then(({ passwordResetToken }) => {
          setPasswordSetToken(passwordResetToken);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [passwordSetToken]);

  return accountActivation && !loading && !passwordSetToken ? (
    // Check for invalid state, somebody is manually messing with urls, send them to login and be done with it.
    <Redirect to={LOGIN.ENTRY} />
  ) : (
    <Suspense loading={loading}>
      <Box minWidth={300}>
        {!passwordSetToken && <PasswordSetRequestForm />}
        {passwordSetToken && (
          <PasswordSetForm passwordSetToken={passwordSetToken} accountActivation={accountActivation} />
        )}
      </Box>
    </Suspense>
  );
});
