import React from 'react';

import { Icon } from 'core/components';
import { MdSettingsEthernet, MdRouter, MdDns } from 'react-icons/md';
import { FiGrid, FiTarget, FiGlobe, FiDollarSign, FiCloud, FiCpu } from 'react-icons/fi';
import { AiOutlineLineChart } from 'react-icons/ai';
import { GiRadarSweep } from 'react-icons/gi';
import { GoDatabase } from 'react-icons/go';

import ASN from './ASN';
import CapacityPlan from './CapacityPlan';
import CDN from './CDN';
import CostGroup from './CostGroup';
import CostSnapshot from './CostSnapshot';
import Device from './Device';
import MetricDevice from './MetricDevice';
import Interface from './Interface';
import IP from './IP';
import OttProvider from './OttProvider';
import OttService from './OttService';
import OttType from './OttType';
import Provider from './Provider';
import Journey from './JourneyResult';
import Site from './Site';
import Dashboard from './Dashboard';
import SavedView from './SavedView';
import Prefix from './Prefix';
import Filter from './SavedFilter';
import SyntheticTest from './SyntheticTest';
import Screen from './Screen';

export const RESULT_TYPE_MAP = {
  asn: { label: 'ASNs', Component: ASN, Icon: (props) => <Icon icon={FiTarget} {...props} /> },
  cdn: { label: 'CDNs', Component: CDN, Icon: (props) => <Icon icon={GoDatabase} {...props} /> },
  capacityPlan: {
    label: 'Capacity Plans',
    Component: CapacityPlan,
    Icon: (props) => <Icon icon="search-around" {...props} />
  },
  costGroup: { label: 'Cost Groups', Component: CostGroup, Icon: (props) => <Icon icon={FiDollarSign} {...props} /> },
  costSnapshot: {
    label: 'Cost Snapshots',
    Component: CostSnapshot,
    Icon: (props) => <Icon icon={FiDollarSign} {...props} />
  },
  dashboard: { label: 'Dashboards', Component: Dashboard, Icon: (props) => <Icon icon={FiGrid} {...props} /> },
  device: { label: 'Devices', Component: Device, Icon: (props) => <Icon icon={MdRouter} {...props} /> },
  interface: {
    label: 'Interfaces',
    Component: Interface,
    Icon: (props) => <Icon icon={MdSettingsEthernet} {...props} />
  },
  ip: { label: 'IP Addresses', Component: IP, Icon: (props) => <Icon icon="ip-address" {...props} /> },
  ips: { label: 'IP Addresses', Component: IP, Icon: (props) => <Icon icon="ip-address" {...props} /> },
  metric_device: { label: 'NMS Devices', Component: MetricDevice, Icon: (props) => <Icon icon={FiCpu} {...props} /> },
  ottProvider: { label: 'OTT Providers', Component: OttProvider, Icon: (props) => <Icon icon={FiCloud} {...props} /> },
  ottService: { label: 'OTT Services', Component: OttService, Icon: (props) => <Icon icon={MdDns} {...props} /> },
  ottType: { label: 'OTT Types', Component: OttType, Icon: (props) => <Icon icon="help" {...props} /> },
  prefix: { label: 'Prefixes', Component: Prefix, Icon: (props) => <Icon icon={FiTarget} {...props} /> },
  provider: { label: 'Providers', Component: Provider, Icon: (props) => <Icon icon={FiGlobe} {...props} /> },
  savedFilter: { label: 'Saved Filters', Component: Filter, Icon: (props) => <Icon icon="filter" {...props} /> },
  journey: { label: 'Journeys', Component: Journey, Icon: (props) => <Icon icon="route" {...props} /> },
  savedView: {
    label: 'Saved Views',
    Component: SavedView,
    Icon: (props) => <Icon icon={AiOutlineLineChart} {...props} />
  },
  site: { label: 'Sites', Component: Site, Icon: (props) => <Icon icon="map-marker" {...props} /> },
  syntheticTest: {
    label: 'Synthetic Tests',
    Component: SyntheticTest,
    Icon: (props) => <Icon icon={GiRadarSweep} {...props} />
  },

  screen: { label: 'Screens', Component: Screen }
};
