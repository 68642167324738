import React from 'react';
import { getDeviceValues } from 'app/util/devices';

import $dictionary from 'app/stores/$dictionary';
import $mitigations from 'app/stores/mitigations/$mitigations';
import $metrics from 'app/stores/metrics/$metrics';
import $notifications from 'app/stores/notifications/$notifications';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';

import Tooltip from 'core/components/Tooltip';
import Tag from 'core/components/Tag';
import Box from 'core/components/Box';
import Flex from 'core/components/Flex';

import SelectedValueButtonCompact from 'app/components/device/SelectedValueButtonCompact';
import NotificationOption from 'app/components/notifications/NotificationOption';

import EventPolicyModel from 'app/stores/alerting/kevent/EventPolicyModel';
import NmsPolicyModel from 'app/stores/alerting/nms/NmsPolicyModel';
import PolicyModel from 'app/stores/alerting/PolicyModel';
import { PERCENT_METRICS, GREEK_METRICS, ALERT_METRIC_OPTIONS } from './constants';
import { getNmsDimensionLabel } from './dimensions';

export function getMetricOption(policyMetric = '', selectedMeasurement = '') {
  const fallbackOption = {
    label: 'Unknown',
    value: policyMetric,
    unit: policyMetric
  };

  let metricOption = ALERT_METRIC_OPTIONS.find((option) => option.value === policyMetric);

  if (!metricOption && selectedMeasurement) {
    const measurementModel = $metrics.measurementModel(selectedMeasurement);

    if (measurementModel) {
      metricOption = {
        value: policyMetric,
        label: measurementModel.metricLabel(policyMetric),
        // currently, we do not support units for metric policies metrics
        unit: '',
        unitLabel: ''
      };
    }
  }

  if (!metricOption) {
    metricOption = fallbackOption;
  }

  return {
    unitLabel: $dictionary.get(`units.${metricOption.unit}`) || 'unknown',
    ...metricOption,
    isPercent: PERCENT_METRICS.includes(policyMetric),
    canGreek: GREEK_METRICS.includes(policyMetric)
  };
}

// Updated for v4 policies, takes in policy form
export function setMinimumTraffic(form) {
  const metrics = form.getValue('metrics');
  const [primaryMetric] = metrics;
  const metricOption = getMetricOption(primaryMetric, form.getValue('selectedMeasurement'));

  if (!metricOption || !metricOption?.canGreek) {
    form.setValue('minTrafficValueFactor', 1);
  }

  // We're going to be auto-setting form values, so bail out now if user hasn't agreed
  if (!form.getValue('is_auto_calc')) {
    return;
  }

  const minTrafficValueFactor = form.getValue('minTrafficValueFactor');
  let lowestGlobalValue = 0;

  form.getField('thresholds').forEach((threshold) => {
    if (!threshold.enabled.getValue()) {
      return;
    }

    const conditions = threshold.conditions.getValue();

    let lowestStaticValue = 0;
    let lowestBaselineValue = 0;

    // Find lowest baseline value given our static value
    conditions.forEach((condition) => {
      const { type, value_select, comparisonValue, comparisonValueFactor } = condition;
      const value = comparisonValue * comparisonValueFactor;
      if (type === 'static' && value_select === 'metric_0') {
        lowestStaticValue = !lowestStaticValue || value < lowestStaticValue ? value : lowestStaticValue;
      }
    });

    // Then get the lowest combo of baseline/static values
    conditions.forEach((condition) => {
      const { comparisonValue, comparisonValueFactor, type } = condition;
      const value = type === 'baselinePercent' ? comparisonValue : comparisonValue * comparisonValueFactor;
      const baselineValue = type === 'baselinePercent' ? lowestStaticValue / (value * 0.01) : lowestStaticValue - value;

      lowestBaselineValue =
        !lowestBaselineValue || baselineValue < lowestBaselineValue ? baselineValue : lowestBaselineValue;
    });

    // if static - lowest of threshold values
    // if baseline - lowest of threshold value /(.01 * baseline % or - baseline unit)
    // if mix of static or baseline - lowest of both
    let lowestThresholdValue = lowestStaticValue;

    if (lowestStaticValue && lowestBaselineValue) {
      lowestThresholdValue = lowestStaticValue < lowestBaselineValue ? lowestStaticValue : lowestBaselineValue;
    } else if (lowestBaselineValue) {
      lowestThresholdValue = lowestBaselineValue;
    }

    // if FPS, floor it at 1, else floor it at 0
    lowestThresholdValue = Math.max(primaryMetric === 'fps' ? 1 : 0, lowestThresholdValue);

    // Set fallback value here
    if (Number.isFinite(lowestThresholdValue) && threshold['fallbackSettings.op'].getValue() === 'useThisValue') {
      threshold['fallbackSettings.value'].setValue(lowestThresholdValue);
    }

    // Set the form level min traffic threshold
    lowestGlobalValue =
      !lowestGlobalValue || lowestThresholdValue < lowestGlobalValue ? lowestThresholdValue : lowestGlobalValue;
  });

  if (PERCENT_METRICS.includes(primaryMetric)) {
    lowestGlobalValue /= 1000;
  }

  form.setValue('minTrafficValue', lowestGlobalValue / minTrafficValueFactor);
}

export function getTopItems({ items, max = 2, itemRenderer, tooltipItemRenderer, column }) {
  if (!items?.length > 0) {
    return null;
  }

  const count = items.length;
  const overCount = count > max ? count - max : 0;
  const topItems = items.slice(0, max);

  return (
    <Box>
      {topItems.map((item, idx) => (
        <Flex
          flexDirection={column ? 'column' : undefined}
          key={item.id || item.name || item.label || item.title || idx}
          alignItems={column ? 'flex-start' : 'center'}
        >
          {itemRenderer ? itemRenderer(item, idx) : item}
          {idx === 1 && overCount > 0 && (
            <Tooltip
              content={items.slice(idx + 1).map((overItem, overIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={overIdx}>{tooltipItemRenderer ? tooltipItemRenderer(overItem, overIdx) : overItem}</Box>
              ))}
            >
              <Box>+{overCount} more</Box>
            </Tooltip>
          )}
        </Flex>
      ))}
    </Box>
  );
}

export function getThresholdMitigationTags(mitigations) {
  const selectedMitigations = $mitigations.platformsCollection
    .getPolicyOptions(mitigations || [])
    .filter((m) => m.selected !== undefined);

  return selectedMitigations.map(({ key, methodName, platformName }) => (
    <Box key={key} mt="4px">
      <Tag minimal mr="4px">
        {platformName}
      </Tag>
      {methodName}
    </Box>
  ));
}

export function getNotificationChannelTags(notificationChannels) {
  const selectedValues = notificationChannels || [];
  const channelOptions = $notifications.collection.notificationOptions;
  const selectedChannels = channelOptions.filter((option) => selectedValues.includes(option.value));

  return selectedChannels.map((channel) => (
    <Box mt="4px" key={channel.value}>
      <Tag round minimal small style={{ padding: '2px 6px 2px 0px' }}>
        <NotificationOption option={channel} />
      </Tag>
    </Box>
  ));
}

export function getPolicyDevices({ model }) {
  const { all_devices, device_labels, device_name, device_sites, device_types } = model.get('selected_devices');

  if (all_devices) {
    return ['All Data Sources'];
  }

  const deviceValues = getDeviceValues({
    simpleSiteLabelsOnly: true,
    all_devices,
    device_types,
    device_labels,
    device_sites,
    device_name
  });

  return [
    ...deviceValues.get('types'),
    ...deviceValues.get('labels'),
    ...deviceValues.get('sites'),
    ...deviceValues.get('devices')
  ];
}

export function getPolicyDeviceTag(device, tagProps = {}) {
  if (device === 'All Data Sources') {
    return (
      <SelectedValueButtonCompact label="All Data Sources" icon="multi-select" key="allDataSources" {...tagProps} />
    );
  }

  let iconName = null;

  // If device is a label
  if (device.bg) {
    iconName = 'tag';
  }

  // If device is a site
  if (device.activeDevices) {
    iconName = 'map-marker';
  }

  return <SelectedValueButtonCompact icon={iconName} option={device} key={device.value} {...tagProps} />;
}

export function getPolicyMeasurementTag(measurement, tagProps = {}) {
  return (
    <SelectedValueButtonCompact
      option={$metrics.availableLabelMeasurementOptions.find((m) => m.value === measurement)}
      key={measurement}
      {...tagProps}
    />
  );
}

export function getDimensionOption(policyDimension = '', options = {}) {
  const { useChartTypes = false, reconDimensions, getDimensionObject = false } = options;
  const dimensionOptions =
    reconDimensions || (useChartTypes ? $dictionary.flatDimensionOptions : $dictionary.flatFilterFieldOptions);
  const dimensionDisplay = dimensionOptions.find((option) => option.value === policyDimension);

  if (!dimensionDisplay) {
    return `Invalid: ${policyDimension}`;
  }

  if (getDimensionObject) {
    return dimensionDisplay;
  }

  if (dimensionDisplay.group) {
    return `${dimensionDisplay.group} ${dimensionDisplay.label}`;
  }

  return dimensionDisplay.label;
}

export function getFullSelectedMeasurement(selectedMeasurement) {
  return $metrics.availableMeasurementOptions.find(
    (measurement) => measurement.id === selectedMeasurement || measurement.measurement === selectedMeasurement
  );
}

export function getNmsMetricOption(policyMetric = '', measurements = []) {
  const fallbackOption = {
    label: 'Unknown',
    value: policyMetric,
    unit: policyMetric
  };

  let metricOption = ALERT_METRIC_OPTIONS.find((option) => option.value === policyMetric);

  if (!metricOption && measurements.length > 0) {
    // try to find the measurement that fits the metric
    const appropriateMeasurement = measurements.find((measurement) =>
      $metrics.measurementModel(measurement)?.metricDetails(policyMetric)
    );

    if (appropriateMeasurement) {
      const measurementModel = $metrics.measurementModel(appropriateMeasurement);

      if (measurementModel) {
        metricOption = {
          value: policyMetric,
          label: measurementModel.metricLabel(policyMetric),
          // currently, we do not support units for metric policies metrics
          unit: '',
          unitLabel: ''
        };
      }
    }
  }

  if (!metricOption) {
    metricOption = fallbackOption;
  }

  return {
    unitLabel: $dictionary.get(`units.${metricOption.unit}`) || 'unknown',
    ...metricOption,
    isPercent: PERCENT_METRICS.includes(policyMetric),
    canGreek: GREEK_METRICS.includes(policyMetric)
  };
}

export function getNmsDimensionOption(policyDimension = '', measurements = []) {
  const fallbackOption = {
    label: 'Unknown',
    value: policyDimension,
    unit: policyDimension
  };

  // try to find the measurement that fits the dimension
  let dimensionOption = measurements.reduce((a, measurement) => {
    if (a) {
      return a;
    }

    const measurementModel = $metrics.measurementModel(measurement);

    if (measurementModel) {
      const dimension = measurementModel.storage.Dimensions[policyDimension];

      if (dimension) {
        return { ...dimension, label: getNmsDimensionLabel(policyDimension, dimension) };
      }
    }

    return null;
  }, null);

  if (!dimensionOption) {
    dimensionOption = fallbackOption;
  }

  return dimensionOption;
}

export function getPrimaryMetric(policyMetrics = [], form) {
  const primaryMetric = policyMetrics[0];
  const isMetricPolicy = form.getValue('application') === POLICY_APPLICATIONS.METRIC;

  return (
    primaryMetric &&
    getMetricOption(primaryMetric, isMetricPolicy ? form.getValue('selectedMeasurement') : undefined).label
  );
}

// TODO NMS POLICY - this might see some usage and might need updating as we move forward on detail page
export function getMeasurementModelDataFromPolicy(policy) {
  // If we're in a form context, not a deserialization context
  if (policy.selectedMeasurement) {
    return {
      fullMeasurement: getFullSelectedMeasurement(policy.selectedMeasurement),
      measurement: policy.selectedMeasurement
    };
  }

  // Otherwise, we're in a deserialization context
  const { filters } = policy;
  const metadataMeasurement = policy?.applicationMetadata?.measurement;
  const filterDimension = $metrics.getAppProtocolDimension(metadataMeasurement, 'km_measurement_name');
  const measurementFilterGroupIndex = filters.filterGroups?.findIndex(
    (group) => group.filters[0]?.filterField === filterDimension
  );

  const measurement =
    metadataMeasurement ||
    (measurementFilterGroupIndex >= 0 && filters.filterGroups[measurementFilterGroupIndex].filters[0].filterValue);

  const fullMeasurement = getFullSelectedMeasurement(measurement);

  return {
    fullMeasurement,
    measurement
  };
}

export function mapKmetricDimensions(policy) {
  const { fullMeasurement, measurement } = getMeasurementModelDataFromPolicy(policy);
  const storageDimensions = fullMeasurement?.storage?.Dimensions || {};
  const storageDimensionKeys = Object.keys(storageDimensions);

  return policy.dimensions
    .map((dimension) => {
      const column = dimension.replace($metrics.getAppProtocolDimension(measurement), '');
      // these dimensions are always secretly injected into kmetrics policies
      const omitDimension = ['km_device_id', 'km_measurement_name'].includes(column);

      return (
        !omitDimension && (storageDimensionKeys.find((key) => storageDimensions[key]?.Column === column) || column)
      );
    })
    .filter(Boolean);
}

export function getKmetricDimensionLabelForPolicy(policy, dimension) {
  const { fullMeasurement, measurement } = getMeasurementModelDataFromPolicy(policy);
  const baseDimension = dimension.replace($metrics.getAppProtocolDimension(measurement), '');

  if (fullMeasurement) {
    const keyMatch = Object.keys(fullMeasurement.storage.Dimensions).find(
      (key) => fullMeasurement.storage.Dimensions[key].Column === baseDimension
    );
    return keyMatch || baseDimension;
  }

  return baseDimension;
}

export function isNmsPolicy(policy) {
  return policy?.application === POLICY_APPLICATIONS.NMS;
}

export function getPolicyModelFromAttributes(policyAttributes, deserialize = true) {
  switch (policyAttributes?.application) {
    case POLICY_APPLICATIONS.NMS:
      return new NmsPolicyModel(policyAttributes, { deserialize });
    case POLICY_APPLICATIONS.KEVENT:
      return new EventPolicyModel(policyAttributes, { deserialize });
    default:
      return new PolicyModel(policyAttributes, { deserialize });
  }
}
