import React, { Fragment } from 'react';
import Callout from 'core/components/Callout';
import Box from 'core/components/Box';

export const SsoCallout = () => (
  <Fragment>
    <Callout title="Don't have Single Sign-on?" mt={2} p={2}>
      <Box>
        If your company doesn’t have Kentik Single Sign-on and you’d like to see this feature enabled, please contact
        your Kentik system administrator to request it.
      </Box>
    </Callout>
  </Fragment>
);
