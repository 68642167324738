import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, ConfirmDialog, FlexColumn, Text, Icon } from 'core/components';
import Flex from 'core/components/Flex';
import { CheckboxGroup, Field, Form } from 'core/form';
import AdminFilterItemRenderer from 'app/components/admin/AdminFilterItemRenderer';
import Heading from 'core/components/Heading';
import { withTheme } from 'styled-components';
import KentikLogo from 'app/components/KentikLogo';
import MultiSelectMenu from 'core/components/MultiSelectMenu';
import UserCloneRoleMenu from 'app/views/settings/users/UserCloneRoleMenu';
import { Intent } from '@blueprintjs/core';
import Collection from 'core/model/Collection';
import { BsArrowReturnRight } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';

const fields = {
  kmrRoles: {
    defaultValue: []
  },
  customRoles: {
    label: 'Custom Roles',
    defaultValue: []
  },
  roleSets: {
    label: 'Role Sets',
    defaultValue: []
  }
};

const RoleSetItemRenderer = ({ label, displayLabel, roles = [] }) => (
  <FlexColumn overflow="hidden" gap="4px">
    <Text as="div" ellipsis={false} title={label}>
      {displayLabel || label}
    </Text>
    {roles.map((role) => (
      <Flex key={role} alignItems="center">
        <Icon icon={BsArrowReturnRight} color="muted" iconSize={12} />
        <Text muted small>
          {role}
        </Text>
      </Flex>
    ))}
  </FlexColumn>
);

const options = { name: 'Assigned Roles' };
@Form({ fields, options })
@inject('$rbac')
@withTheme
@observer
export default class UserAssignedRolesForm extends Component {
  state = {
    isRemoveRoleDialogOpen: false
  };

  allCollection = new Collection();

  componentDidMount() {
    const { $rbac } = this.props;

    this.allCollection.add([
      ...$rbac.collection.map((model) => model.duplicate({ save: false, removeId: false })),
      ...$rbac.roleSetCollection.map((model) => model.duplicate({ save: false, removeId: false }))
    ]);
    this.allCollection.each((model) => {
      model.set('id', `${model.isRoleSet ? 'roleSet' : 'role'}|${model.get('id')}`);
    });
  }

  addItemRenderer(item) {
    // role set
    if (item.isRoleSet) {
      return (
        <Flex alignItems="center">
          <Icon icon={HiUserGroup} color="muted" mr={1} />
          <Text>{item.get('name')}</Text>
        </Flex>
      );
    }

    // role
    if (item.get('company_id')) {
      return item.get('role_name');
    }

    return (
      <Flex alignItems="center">
        <KentikLogo style={{ width: 16 }} onlyMark mr="2px" />

        <Text>{item.get('role_name')}</Text>
      </Flex>
    );
  }

  get blackList() {
    const { roleSetCollection, roleCollection } = this.props;

    if (!this.allCollection.size) {
      return [];
    }

    const roleSetIds = roleSetCollection.map((rs) => rs.id);
    const roleIds = roleCollection.map((role) => role.id);

    return this.allCollection.filter(
      (item) => {
        const itemId = parseInt(item.id.split('|')[1]);

        if (item.isRoleSet) {
          return roleSetIds.includes(itemId);
        }

        return roleIds.includes(itemId);
      },
      { immutable: true }
    );
  }

  get removalConfirmText() {
    const { form, $rbac } = this.props;
    const roles = form
      .getValue('kmrRoles')
      .concat(form.getValue('customRoles'))
      .map((roleId) => $rbac.collection.get(roleId));

    const roleSets = form.getValue('roleSets').map((roleSetId) => $rbac.roleSetCollection.get(roleSetId));
    const rolesFromRoleSets = Array.from(new Set(roleSets.map((rs) => rs.get('roles')).flat())).map((roleId) =>
      $rbac.collection.get(roleId)
    );

    const permissions = new Set();
    roles.forEach((role) => role.get('permissions').forEach((perm) => permissions.add(perm)));
    rolesFromRoleSets.forEach((role) => role.get('permissions').forEach((perm) => permissions.add(perm)));

    return (
      <Box>
        <Text as={Box} mb={1}>
          Are you sure you want to remove the following roles/role sets?
        </Text>
        <Text>Roles</Text>
        <ul>
          {roles.map((role) => (
            <li key={role.id}>{role.get('role_name')}</li>
          ))}
        </ul>
        <Text>Role Sets</Text>
        <ul>
          {roleSets.map((roleSet) => (
            <li key={roleSet.id}>{roleSet.get('name')}</li>
          ))}
        </ul>
        <Text>The user will lose these permissions:</Text>
        <ul>
          {Array.from(permissions).map((perm) => (
            <li key={perm}>{$rbac.getPermissionNameFromKey(perm)}</li>
          ))}
        </ul>
      </Box>
    );
  }

  handleShowRemoveDialog = () => {
    this.setState({ isRemoveRoleDialogOpen: true });
  };

  handleHideRemoveDialog = () => {
    this.setState({ isRemoveRoleDialogOpen: false });
  };

  handleRemoveItem = () => {
    const { form, onRemoveItems } = this.props;
    const roles = form.getValue('kmrRoles').concat(form.getValue('customRoles'));
    const roleSets = form.getValue('roleSets');
    onRemoveItems({ roles, roleSets });
    form.reset();
    this.handleHideRemoveDialog();
  };

  render() {
    const { form, theme, kmrRoles, customRoles, roleSets, onAddItems, selectedUser } = this.props;
    const { isRemoveRoleDialogOpen } = this.state;
    const hasRolesOrSetsSelected =
      form.getValue('kmrRoles').concat(form.getValue('customRoles'), form.getValue('roleSets')).length > 0;

    return (
      <FlexColumn flex={1}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            p="7px 8px"
            borderBottom="thin"
            style={{
              background: theme.backgrounds.tableHeader
            }}
          >
            <Flex alignItems="center" flex="1 1 auto">
              <Heading level={5} fontWeight="heavy" mb={0}>
                Assigned Roles and Sets
              </Heading>
            </Flex>
          </Flex>
        </Flex>
        <FlexColumn justifyContent="space-between" flex={1}>
          <FlexColumn flex="1 1 0%" p="12px" overflow="auto">
            {kmrRoles.length > 0 && (
              <Field
                name="kmrRoles"
                label={
                  <Flex alignItems="center">
                    <KentikLogo style={{ width: 16 }} onlyMark mr="2px" />
                    Kentik Managed Roles
                  </Flex>
                }
                options={kmrRoles}
                large
              >
                <CheckboxGroup itemRenderer={AdminFilterItemRenderer} />
              </Field>
            )}
            {customRoles.length > 0 && (
              <Field name="customRoles" options={customRoles} large>
                <CheckboxGroup itemRenderer={AdminFilterItemRenderer} />
              </Field>
            )}
            {roleSets.length > 0 && (
              <Field name="roleSets" options={roleSets} large>
                <CheckboxGroup itemRenderer={RoleSetItemRenderer} />
              </Field>
            )}
          </FlexColumn>
          <Flex mb={2} justifyContent="center" gap={1}>
            <Box>
              <MultiSelectMenu
                collection={this.allCollection}
                blackList={this.blackList}
                itemRenderer={this.addItemRenderer}
                buttonText="Add Role/Set"
                buttonIcon="plus"
                onApply={onAddItems}
              />
            </Box>

            <Box>
              <UserCloneRoleMenu selectedUser={selectedUser} onApply={onAddItems} collection={this.allCollection} />
            </Box>
            <Box>
              <Button
                ariaLabel="Remove Role/Set from User"
                intent={Intent.DANGER}
                icon="trash"
                disabled={!hasRolesOrSetsSelected}
                text=""
                onClick={this.handleShowRemoveDialog}
              />
              {isRemoveRoleDialogOpen && (
                <ConfirmDialog
                  bodyProps={{ overflow: 'auto', p: 2 }}
                  title="Remove Role/Set from User"
                  text={this.removalConfirmText}
                  isOpen={isRemoveRoleDialogOpen}
                  onCancel={this.handleHideRemoveDialog}
                  onConfirm={this.handleRemoveItem}
                />
              )}
            </Box>
          </Flex>
        </FlexColumn>
      </FlexColumn>
    );
  }
}
