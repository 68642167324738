import styled from 'styled-components';
import React, { memo, useEffect, useRef } from 'react';

const STATIC_ANIMATION_SLOWDOWN = 5;
const BLEND_MODE = 'normal';

/**
 * @param {string} hex hex value (from theme) for color
 * @returns {string} gradient color for background
 */
const gradientColors = (hex) => {
  if (!hex) {
    console.error('hex color code not provided for gradient');
    return '';
  }
  const rgb = `${parseInt(hex.slice(1, 3), 16)},${parseInt(hex.slice(3, 5), 16)},${parseInt(hex.slice(5, 7), 16)}`;
  return `radial-gradient(circle at center, rgba(${rgb}, 0.8) 0, rgba(${rgb}, 0) 50%) no-repeat`;
};

const LoginWrapperBackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right top, #096dd9, 70%, #00c2cc);
  top: 0;
  left: 0;
`;

const LoginWrapperSvgContainer = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
`;

const LoginAnimatedGradientDiv = styled.div`
  @keyframes moveInCircle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes moveVertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }

  @keyframes moveHorizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }

  position: absolute;
  mix-blend-mode: ${BLEND_MODE};

  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;

  opacity: 1;

  &.g1 {
    transform-origin: center center;
    animation: moveVertical ${STATIC_ANIMATION_SLOWDOWN * 3}s ease infinite;
    background: ${gradientColors('#fcfaf4')};
  }
  &.g2 {
    transform-origin: calc(50% - 400px);
    animation: moveInCircle ${STATIC_ANIMATION_SLOWDOWN * 2}s reverse infinite;
    background: ${gradientColors('#1890ff')};
  }
  &.g3 {
    transform-origin: calc(50% + 400px);
    animation: moveInCircle ${STATIC_ANIMATION_SLOWDOWN * 4}s linear infinite;
    background: ${gradientColors('#00c2cc')};
  }
  &.g4 {
    transform-origin: calc(50% - 200px);
    animation: moveHorizontal ${STATIC_ANIMATION_SLOWDOWN * 4}s ease infinite;
    opacity: 0.7;
    background: ${gradientColors('#fcfaf4')};
  }
  &.g5 {
    width: 160%;
    height: 160%;
    top: -30%;
    left: -30%;
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle ${STATIC_ANIMATION_SLOWDOWN * 2}s ease infinite;
    background: ${gradientColors('#01b4bd')};
  }
`;

const LoginAnimatedFollower = styled.div`
  position: absolute;
  background: ${gradientColors('#9881f3')};
  mix-blend-mode: ${BLEND_MODE};
  transition: transform 1.5s ease;

  width: 100%;
  height: 100%;
  top: -50%;
  left: -50%;

  opacity: 0.7;
`;

const LoginAnimatedGradientContainer = styled.div`
  filter: url(#goo) blur(40px);
  width: 100%;
  height: 100%;
`;

const LoginWrapperSvg = () => (
  <LoginWrapperSvgContainer xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </LoginWrapperSvgContainer>
);

export const LoginBackground = memo(({ pos }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.style.transform = `translate(${pos[0]}px, ${pos[1]}px)`;
  }, [pos]);

  return (
    <LoginWrapperBackgroundContainer>
      <LoginWrapperSvg />
      <LoginAnimatedGradientContainer>
        <LoginAnimatedGradientDiv className="g1" />
        <LoginAnimatedGradientDiv className="g2" />
        <LoginAnimatedGradientDiv className="g3" />
        <LoginAnimatedGradientDiv className="g4" />
        <LoginAnimatedGradientDiv className="g5" />
        <LoginAnimatedFollower ref={ref} />
      </LoginAnimatedGradientContainer>
    </LoginWrapperBackgroundContainer>
  );
});
