import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import $auth from 'app/stores/$auth';
import { getPortalVersion } from 'core/util/index';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import { useHistory, useLocation } from 'react-router-dom';

export const LoginContext = createContext({});

const useSubmitLogin = () => {
  const location = useLocation();
  const history = useHistory();

  return useCallback(
    async (form, credentials) =>
      $auth.authenticate(credentials).then((result) => {
        if (result.success) {
          const pathname = $auth.isSynOnboarding ? '/v4/synthetics/tests/' : (location?.state?.from?.pathname ?? '/');
          const search = location?.state?.from?.search ?? '';
          let targetLocation = `${pathname}${search}`;

          if (!$auth.isSharedUser && $auth.twoFactorVerifyRequired) {
            targetLocation = LOGIN.TWO_FACTOR;
          }

          // If the loaded version is out of date, let's just force a reload now
          getPortalVersion().then(({ hasOutdatedBundle }) => {
            if (hasOutdatedBundle) {
              window.location = targetLocation;
            } else {
              history.replace({ pathname: targetLocation, state: location.state });
            }
          });
        } else if (result.isAcl) {
          form.invalidate('Login was blocked due to a configured ACL.');
        } else {
          form.invalidate('Your email and password combination were incorrect.');
        }
      }),
    [history, location]
  );
};

/**
 * @param children {React.ReactNode}
 * @param subtenancy {object | undefined}
 * @param ssoCompanyIdentifier {string | undefined}
 * @returns {JSX.Element}
 * @constructor
 */
export const LoginContextProvider = observer(({ children, subtenancy, ssoCompanyIdentifier }) => {
  const handleSubmit = useSubmitLogin();
  const context = useMemo(
    () => ({ subtenancy, handleSubmit, ssoCompanyIdentifier }),
    [handleSubmit, ssoCompanyIdentifier, subtenancy]
  );
  return <LoginContext.Provider value={context}>{children}</LoginContext.Provider>;
});

/**
 * @returns {{subtenancy?: object, ssoCompanyIdentifier?: string, handleSubmit: (form: object, credentials: object) => void}}
 */
export const useLoginContext = () => useContext(LoginContext);
