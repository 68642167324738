import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { FiHeart } from 'react-icons/fi';

import { Box, Button, Flex, Icon, LinkButton, Popover, Text } from 'core/components';
import { getHealthState } from 'app/views/hybrid/utils/health';

@inject('$hybridMap')
export default class HealthIssuesMenu extends Component {
  state = {
    healthCollection: null
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    $hybridMap
      .getHealthCollection()
      .then((healthCollection) => {
        this.setState({ healthCollection });
      })
      .catch((e) => {
        console.error('Error loading topology', e);
      });
  }

  get content() {
    const { $hybridMap } = this.props;
    const { healthCollection } = this.state;
    const { size } = healthCollection;

    if (size === 0) {
      return <Box p={2}>No health problems found on you network!</Box>;
    }

    const { byType, numSites } = healthCollection;
    const types = Object.keys(byType).sort((a, b) => byType[b].length - byType[a].length);

    return (
      <Box p={2}>
        Your network has {size} problem{size === 1 ? '' : 's'} across {numSites} site{numSites === 1 ? '' : 's'}
        <ul className={Classes.LIST}>
          {types.map((type) => (
            <li key={type}>{byType[type].description}</li>
          ))}
        </ul>
        <LinkButton to={`${$hybridMap.baseRoute}/health`} text="View Problems" intent="primary" mt={1} />
      </Box>
    );
  }

  render() {
    const { healthCollection } = this.state;

    if (!healthCollection) {
      return <div />;
    }

    const { overallState, size } = healthCollection;
    const { intent } = getHealthState(overallState);

    return (
      <Popover content={this.content} position="bottom">
        <Button ariaLabel="Health Problems">
          <Flex gap="4px" alignItems="center">
            <Box position="relative">
              <Icon icon={FiHeart} color={intent} iconSize={20} />
              <Box
                color={intent}
                fontSize="0.75em"
                fontWeight="heavy"
                lineHeight={1}
                position="absolute"
                left="50%"
                top="50%"
                style={{ transform: 'translate(-50%, -50%)' }}
              >
                {overallState !== 'GOOD' ? '!' : ''}
              </Box>
            </Box>
            <Text color={intent}>{size}</Text>
          </Flex>
        </Button>
      </Popover>
    );
  }
}
