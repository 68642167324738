import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Intent, Spinner } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';
import { MdOutlineMarkEmailRead } from 'react-icons/md';

import { Box, Button, CalloutOutline, Heading, Text } from 'core/components';
import { Field, Form, InputGroup } from 'core/form';
import { fields, options } from 'app/forms/config/passwordSetRequest';
import { LoginBackButton } from 'app/views/login/components/LoginBackButton';
import Icon from 'core/components/Icon';

@Form({ fields, options })
@inject('$app', '$auth')
@observer
class PasswordSetRequestForm extends Component {
  state = {
    ready: false,
    error: false,
    recaptchaEnabled: false,
    recaptchaSiteKey: null,
    passwordReqComplete: false
  };

  componentDidMount() {
    const { $auth, form } = this.props;
    $auth
      .getOpenConfig()
      .then((res) => {
        const { recaptchaEnabled, recaptchaSiteKey } = res;
        form.getField('recaptcha').setRules(recaptchaEnabled ? 'required' : []);
        this.setState({ recaptchaEnabled, recaptchaSiteKey, ready: true });
      })
      .catch(() => {
        this.setState({ ready: true, error: true });
      });
  }

  recaptchaCallback = (response) => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    const { form } = this.props;
    form.getField('recaptcha').setValue(null);
  };

  handlePasswordSetRequestSubmit = (formArg, formData) => {
    const { $auth, form } = this.props;
    const { recaptchaEnabled } = this.state;
    const { user_email, recaptcha } = formData;
    return $auth.requestPasswordReset({ user_email, recaptcha }).then((result) => {
      if (recaptchaEnabled) {
        window.grecaptcha.reset();
        form.getField('recaptcha').setValue(null);
      }

      if (result.success) {
        this.setState({ passwordReqComplete: true });
      } else {
        const { status } = result;
        let msg;
        if (status === 400) {
          msg = 'Invalid request. Please check user email.';
        } else if (status === 404) {
          msg = `Account email (${user_email}) not found`;
        } else {
          msg = 'Unexpected Error, please contact administrator.';
        }
        formArg.invalidate(msg);
      }
    });
  };

  render() {
    const { form, $app, $auth } = this.props;
    const { ready, error, recaptchaEnabled, recaptchaSiteKey, passwordReqComplete } = this.state;

    // waiting for open config call
    if (!ready) {
      return <Spinner />;
    }

    // open config call complete, but error
    if (ready && error) {
      return <span> Error loading page </span>;
    }

    return (
      <>
        <Box pb={1}>
          <Heading level={4} fontWeight="heavy">
            Reset Password
          </Heading>
          {$auth.isExpiredPassword && (
            <Text muted>Your password has expired. Enter the email address of your account to reset password</Text>
          )}
        </Box>
        {passwordReqComplete && (
          <CalloutOutline p={2} intent={Intent.SUCCESS} mb={2}>
            <Heading level={5} fontWeight="heavy" mb="4px">
              <Icon icon={MdOutlineMarkEmailRead} mr={1} mb="2px" />
              <Text>Success!</Text>
            </Heading>
            <Text fontSize="13px" as="div" mb="4px">
              An email containing instructions to complete password reset has been sent.
            </Text>
          </CalloutOutline>
        )}
        <Field name="user_email" autoFocus showLabel showRequired={false} placeholder="johndoe@email.com">
          <InputGroup large className="jumbo" />
        </Field>
        {recaptchaEnabled && (
          <Box mb={2}>
            <Recaptcha
              sitekey={recaptchaSiteKey}
              callback={this.recaptchaCallback}
              expiredCallback={this.recaptchaExpire}
            />
          </Box>
        )}
        {form.error && (
          <CalloutOutline p={2} intent="danger">
            <Heading color="danger" level={5} fontWeight="heavy">
              Password Reset Request Failed
            </Heading>
            <Text as="div" mb="4px">
              {form.error}
            </Text>
            <a href="/" target="_blank">
              {`Return to ${$app.appName}`}
            </a>
          </CalloutOutline>
        )}
        <Button
          large
          fill
          intent={Intent.PRIMARY}
          text="Request Password Reset"
          disabled={!form.dirty || !form.valid}
          loading={$auth.passwordReqInProgress}
          onClick={() => form.submit(this.handlePasswordSetRequestSubmit)}
          mt={1}
        />
        <LoginBackButton />
      </>
    );
  }
}

export default PasswordSetRequestForm;
