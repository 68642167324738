const options = {
  name: 'SSO Lookup',
  suppressFormValues: true
};

const fields = {
  email: {
    label: 'Email address',
    rules: 'required|email'
  },
  recaptcha: {}
};

export { fields, options };
