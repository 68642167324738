import { EVENT_POLICY_TYPES } from '@kentik/ui-shared/alerting/constants';

export const TARGET_TYPES = {
  ENTITY: 'entity',
  EVENT: 'event',
  CUSTOM: 'custom'
};

export const EVENT_TYPE_OPTIONS = [
  { label: 'Syslog', value: EVENT_POLICY_TYPES.SYSLOG },
  { label: 'SNMP Trap', value: EVENT_POLICY_TYPES.SNMP_TRAP }
];

export const CONDITION_OPERATOR_LABELS = {
  inSet: 'is',
  notInSet: 'is not',
  regexMatch: 'matches regex',
  regexNotMatch: 'does not match regex'
};
