import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';

import { Box, Button, Callout, Flex, Heading, Icon, showClientErrorToast, Text } from 'core/components';
import { Field, Form, InputGroup } from 'core/form';
import { fields, options } from 'app/forms/config/ssoLookup';
import $auth from 'app/stores/$auth';
import { LoginBackButton } from 'app/views/login/components/LoginBackButton';
import { SsoLookups } from 'app/stores/auth/SsoLookups';
import CalloutOutline from 'core/components/CalloutOutline';

const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

export const SsoLookup = Form({ fields, options })(
  observer(({ form }) => {
    const { search } = useLocation();
    const [ssoRedirectPath, setSsoRedirectPath] = React.useState('');
    const [validCompanies, setCompanies] = React.useState(null);
    const [lookupInProgress, setLookupInProgress] = React.useState(false);
    const [lookup, setLookup] = React.useState(null);

    useEffect(() => {
      $auth
        .getOpenConfig()
        .then(() => {
          form.getField('recaptcha').setRules($auth.openConfig.recaptchaEnabled ? 'required' : []);
        })
        .catch(() => {
          showClientErrorToast('Error fetching initial configuration');
        });

      if (!$auth.openConfig.initialReferer.includes('/ssolookup')) {
        sessionStorage.setItem('kentik.ssoInitialReferer', $auth.openConfig.initialReferer);
      }
    }, [form]);

    useEffect(() => {
      const params = new URLSearchParams(search);
      // if we're provided by an email from external context (i.e. login page)
      if (params.get('email')) {
        const email = params.get('email');
        // ... then pre-populate the email in this form
        form.setValue('email', email);
        setLookup(email);
        // and if possible, do the lookup straight away in the background (this lookup is most likely cached already)
        SsoLookups.lookup({ email }).then(({ companies } = {}) => {
          if (companies?.length) {
            setCompanies(companies);
            if (companies.length === 1) {
              setSsoRedirectPath(`/sso/${companies[0].company_name}`);
            }
          }
        });
      }
    }, [form, search]);

    const recaptchaCallback = useCallback(
      (response) => {
        form.getField('recaptcha').setValue(response);
      },
      [form]
    );

    const recaptchaExpire = useCallback(() => {
      form.getField('recaptcha').setValue(null);
    }, [form]);

    const handleSubmit = useCallback((localForm, formData) => {
      const { email, recaptcha } = formData;
      setLookupInProgress(true);
      setLookup(email);
      SsoLookups.lookup({
        email,
        recaptcha
      }).then((result) => {
        const { companies, errorStatus } = result;

        if ($auth.openConfig.recaptchaEnabled) {
          window.grecaptcha.reset();
          localForm.getField('recaptcha').setValue(null);
        }

        if (errorStatus || !companies.length) {
          localForm.invalidate(
            "This could mean either your company doesn't have SSO enabled or that we were unable to " +
              'locate a SSO enabled company via email domain within allowed security parameters. If ' +
              'your company has SSO enabled, please contact your Kentik system administrator for Single Sign-on details.'
          );
          setCompanies([]);
        } else if (companies.length > 1) {
          setCompanies(companies);
        } else {
          setSsoRedirectPath(`/sso/${companies[0].company_name}`);
        }
        setLookupInProgress(false);
      });
    }, []);

    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled;

    if (!$auth.openConfig) {
      return null;
    }

    // If ssoRedirect path is set, redirect immediately.
    if (ssoRedirectPath) {
      sessionStorage.setItem('UsedSSOLogin', 'true');
      window.location = ssoRedirectPath;
    }

    return (
      <>
        <Flex flexDirection="column" alignItems="center" pb={2}>
          <Heading level={4}>Locate your company&apos;s SSO page</Heading>
        </Flex>
        <Box>
          <Field
            name="email"
            autoFocus
            labelStyle={labelStyle}
            showLabel
            showRequired={false}
            large
            placeholder="johndoe@email.com"
            helpText="Enter email associated with your Kentik account."
          >
            <InputGroup />
          </Field>
          {showRecaptcha && (
            <Box mb={2}>
              <Recaptcha
                sitekey={$auth.openConfig.recaptchaSiteKey}
                callback={recaptchaCallback}
                expiredCallback={recaptchaExpire}
              />
            </Box>
          )}

          {validCompanies?.length ? (
            <CalloutOutline mb={2} maxHeight="calc(100vh - 620px)" overflow="auto">
              <Heading level={5}>Multiple Results Found</Heading>
              <Text>
                {validCompanies.length > 1 ? 'Multiple r' : 'R'}esults have been found for the provided email domain.
                Select to be redirected to the appropriate Kentik login page.
              </Text>
              <ul className="pt-list pt-list-unstyled">
                {validCompanies.map((company) => (
                  <li key={company.company_name}>
                    <Icon name="dot" />
                    <Link to={`/login/sso/${company.company_name}`}>{company.company_name_full}</Link>
                  </li>
                ))}
              </ul>
            </CalloutOutline>
          ) : null}

          {form.error && (
            <Callout intent="danger" className="pt-icon-error" my={2}>
              <h5>SSO results not found</h5>
              {form.error}
            </Callout>
          )}
          <Button
            large
            fill
            intent={Intent.PRIMARY}
            text="Submit"
            disabled={
              !$auth.openConfig || !form.dirty || !form.valid || (lookup === form.getValue('email') && lookupInProgress)
            }
            loading={lookupInProgress}
            onClick={() => form.submit(handleSubmit)}
            my={1}
          />
          <LoginBackButton />
        </Box>
      </>
    );
  })
);
