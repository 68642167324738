import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { Flex, FlexColumn, generateTheme, sassVariables, Spinner } from 'core/components';
import overrides from 'core/components/styles/overrides';
import getErrorBoundary from 'core/util/getErrorBoundary';
import initValidators from 'app/forms/validations';
import { LoginRoutes } from 'app/views/login';
import ProtectedRoute from 'app/components/ProtectedRoute';
import JWTRedirect from 'app/views/login/components/JWTRedirect';
import Expired from 'app/views/Expired';
import VerifyEmail from 'app/views/VerifyEmail';
import App from 'app/views/App';
import NotFound from 'app/views/NotFound';
import SharedNotFound from 'app/views/SharedNotFound';
import MKPApp from 'app/views/MKPApp';
import { NAV_HEIGHT } from 'app/components/page/Page';
import { isPathSharedLink } from 'app/util/sharedLink';
import { createHistogram } from 'core/util/uiMetrics';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import { RedirectWithQuery } from 'core/util/RedirectWithQuery';
import LegacyRedirects from 'app/views/LegacyRedirects';

const ErrorBoundaryCmp = getErrorBoundary('AppWrapper');

const theme = generateTheme(sassVariables);
const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
    margin: 0;
    background: ${(props) => props.theme.colors.appBackground};
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  }

  .no-margin {
    margin: 0 !important;
  }

  ${overrides};
`;

const SuspendedComponent = () => <Expired title="Suspended Account" message="Your Account is Suspended" />;
@inject('$app', '$auth', '$store', '$sharedLinks')
@withRouter
@observer
class AppWrapper extends Component {
  state = {
    appReady: false
  };

  first_render_histogram = createHistogram({
    name: 'AppWrapper_first_render_pass_duration_seconds',
    description: 'AppWrapper spinning in seconds'
  });

  appReady_histogram = createHistogram({
    name: 'AppWrapper_appReady_duration_seconds',
    description: 'appReady in seconds'
  });

  constructor(props) {
    super(props);
    // histograms start here
    this.first_render_histogram.start();
    this.appReady_histogram.start();

    const { history, $store, $app } = props;
    $store.setHistory(history);

    history.listen((location, action) => {
      $app.routeHistory.push({ location: location.pathname + (location.search ? `?${location.search}` : ''), action });
      $app.routeHistory = $app.routeHistory.slice(Math.max(0, $app.routeHistory.length - 20));
    });

    initValidators();
  }

  componentDidMount() {
    const { $app, $auth, $store, $sharedLinks } = this.props;
    $app.setIsSharedLink(isPathSharedLink());

    $auth.initialize();

    $auth
      .verifySharedLink()
      .then((data) => {
        if (!data) {
          throw new Error('Invalid shared public hash.');
        }

        $sharedLinks.setData(data);

        Promise.all([$auth.verifyAuth(), $auth.getOpenConfig()])
          .then(([authenticated, openConfig]) => {
            if (openConfig.subtenancy) {
              $app.setIsSubtenant(true);
            }

            if (
              authenticated &&
              ((!$auth.twoFactorVerifyRequired && $auth.getActiveUserProperty('company.company_status') === 'V') ||
                $auth.isSharedUser)
            ) {
              return $store.initializeApp();
            }
            return null;
          })
          .then(() => {
            this.setState({ appReady: true });
            window.onunload = () => {
              // directly set appReady to prevent redirect to login by ProtectRoute when user reloads
              this.state.appReady = false;
              $store.destroy();
            };
          });
      })
      .catch(() => {
        window.onunload = () => $store.destroy();
        $auth.logout().then(() => {
          window.location = '/shared-404';
        });
      });
  }

  componentWillUnmount() {
    const { $store } = this.props;
    $store.destroy();
  }

  get darkThemeEnabled() {
    const { location, $app } = this.props;
    const darkThemeDisabledRoutes = ['/v4/onboarding', LOGIN.ENTRY, LOGIN.TWO_FACTOR];

    return $app.isDarkTheme && darkThemeDisabledRoutes.every((route) => !location.pathname.startsWith(route));
  }

  render() {
    const { $app } = this.props;
    const { appReady } = this.state;
    if (!appReady) {
      // first render pass ends here
      this.first_render_histogram.end();
      return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
          <Spinner intent="primary" size={48} mt={-150} />
        </Flex>
      );
    }
    // appReady ends here
    this.appReady_histogram.end();
    return (
      <ErrorBoundaryCmp>
        <ThemeProvider theme={theme[this.darkThemeEnabled ? 'dark' : 'light']}>
          <FlexColumn
            id="app-wrapper"
            className={this.darkThemeEnabled ? 'bp4-dark' : undefined}
            height="100vh"
            overflow="auto"
            position="relative"
            pt={$app.isSharedLink ? '60px' : `${NAV_HEIGHT}px`}
          >
            <Switch>
              <Route path="/login" component={LoginRoutes} />
              {/* Redirects for old login routes */}
              <Route exact path="/v4/ssolookup" render={() => <RedirectWithQuery to={LOGIN.SSO_LOOKUP} />} />
              <Route exact path="/v4/password" render={() => <RedirectWithQuery to={LOGIN.PASSWORD_RECOVERY} />} />
              <Route
                exact
                path="/v4/account-activation"
                render={() => <RedirectWithQuery to={LOGIN.ACCOUNT_ACTIVATION} />}
              />
              <Route exact path="/verify-two-factor" render={() => <RedirectWithQuery to={LOGIN.TWO_FACTOR} />} />
              {/* End of login route redirects */}

              <ProtectedRoute exact path="/expired-trial" component={Expired} />
              <ProtectedRoute
                exact
                path="/inactive-company"
                render={() => <Expired title="Inactive Account" message="Your Account is Inactive" />}
              />
              <ProtectedRoute
                exact
                path="/suspended-company"
                render={() => <Expired title="Suspended Account" message="Your Account is Suspended" />}
              />

              <ProtectedRoute exact path="/email/verify" component={VerifyEmail} />
              <ProtectedRoute exact path="/v4/email/verify" component={VerifyEmail} />
              <ProtectedRoute exact path="/v4/zendesk-sso" component={JWTRedirect} />
              <ProtectedRoute exact path="/" render={() => <Redirect to="/v4" />} />

              {$app.isSharedLink && <ProtectedRoute path="/v4" component={App} />}

              {!$app.isSharedLink && (
                <ProtectedRoute
                  path="/v4"
                  permission="portal.enabled"
                  overrideForSudo={false}
                  component={$app.isSubtenant ? MKPApp : App}
                  forbiddenComponent={SuspendedComponent}
                />
              )}

              <LegacyRedirects />

              <Route path="/shared-404" component={SharedNotFound} />

              <Route path="*" component={NotFound} />
            </Switch>
          </FlexColumn>
          <GlobalStyles />
        </ThemeProvider>
      </ErrorBoundaryCmp>
    );
  }
}

export default AppWrapper;
