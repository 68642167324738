import { useLoginContext } from 'app/views/login/entry/context';
import { Box, Heading } from 'core/components';
import { LoginForm } from 'app/views/login/entry/LoginForm';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import $auth from 'app/stores/$auth';
import Model from 'core/model/Model';
import { LoginViaSSO } from 'app/views/login/entry/LoginViaSso';

class LoginModel extends Model {
  get defaults() {
    return {
      user_email: '',
      password: ''
    };
  }
}

const LoginStandardEntry = observer(() => {
  const [emptyUser] = useState(new LoginModel());
  const { subtenancy } = useLoginContext();
  return (
    <Box>
      <Heading level={4} mb={2}>
        {subtenancy ? subtenancy.config?.branding?.appName || 'Network Visibility Portal' : 'Sign in'}
      </Heading>
      <LoginForm
        authenticating={$auth.authenticating}
        authenticated={$auth.authenticated}
        model={emptyUser}
        openConfig={$auth.openConfig}
        showLoginRecaptcha={$auth.showLoginRecaptcha}
      />
    </Box>
  );
});

export const LoginFormDiscriminator = () => {
  const { ssoCompanyIdentifier } = useLoginContext();
  return ssoCompanyIdentifier ? <LoginViaSSO /> : <LoginStandardEntry />;
};

export const _testOnly = {
  LoginStandardEntry
};
