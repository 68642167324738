import { Box } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';
import $auth from 'app/stores/$auth';
import React, { memo } from 'react';
import { LOGIN } from '@kentik/ui-shared/paths/login';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

const LoginLogoSubtenant = () => {
  const { subtenancy } = $auth.openConfig;
  return (
    <img
      alt={subtenancy.subdomain}
      src={`/api/ui/icons/${subtenancy.company_id}`}
      style={{ width: 120, height: 'auto' }}
    />
  );
};

export const LoginLogoKentik = () => <KentikLogo blackText style={{ width: 120, height: 25 }} />;

export const LoginLogo = memo(({ onClick, ...props }) => {
  const { subtenancy } = $auth.openConfig;

  const logo = subtenancy?.config?.icon ? <LoginLogoSubtenant /> : <LoginLogoKentik />;

  const logoWrapper = onClick ? (
    <Button minimal p={0} onClick={onClick}>
      {logo}
    </Button>
  ) : (
    <Link to={LOGIN.ENTRY}>{logo}</Link>
  );

  return (
    <Box textAlign="center" mb={4} {...props}>
      {logoWrapper}
    </Box>
  );
});
