import { Flex, Link } from 'core/components';
import Button from 'core/components/Button';
import Text from 'core/components/Text';
import React from 'react';
import { LOGIN } from 'shared/paths/login';
import $store from 'app/$store';

export const LoginBackButton = ({ withLogout = false }) => (
  <Flex flexDirection="row" justifyContent="center" my={2}>
    {withLogout ? (
      <Button minimal textColor="primary" onClick={$store.logout}>
        <Text>Back</Text>
      </Button>
    ) : (
      <Link to={LOGIN.ENTRY}>
        <Text>Back</Text>
      </Link>
    )}
  </Flex>
);
