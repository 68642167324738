import React from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { Box, BorderedTab, BorderedTabs, Button, Flex, showSuccessToast } from 'core/components';
import PageHeading from 'app/components/page/PageHeading';
import { Field, Form, InputGroup } from 'core/form';

import RbacPermCollection from 'app/stores/rbac/RbacPermCollection.js';
import RbacRoleUsers from './RbacRoleUsersV2';
import RbacRolePermissions from './RbacRolePermissionsV2';

const fieldConfigs = {
  role_name: {
    rules: 'required',
    label: 'Name',
    messages: {
      required: 'You must name the RBAC role.'
    }
  },
  role_description: {
    rules: 'required',
    label: 'Description',
    messages: {
      required: 'Please provide a description for this role.'
    }
  },
  permissions: {}
};

@Form({ fields: fieldConfigs, options: { name: 'RbacRoleDetailsForm', showPristineErrors: false } })
@inject('$rbac')
@observer
class RbacRoleDetailsForm extends React.Component {
  state = {
    selectedTabId: undefined
  };

  collection = new RbacPermCollection([], { groupBy: 'group' });

  componentDidMount() {
    const { model } = this.props;
    // ONLY DO HERE!  Or if you refetch, you need to updateEnabledPermissions immediately after
    this.collection.fetch({ force: true }).then(() => {
      this.updateEnabledPermissions(model);
    });
  }

  componentDidUpdate(prevProps) {
    const { model: prevModel } = prevProps;
    const { model } = this.props;

    // the key scenario is, after saving a new role, the URL is updated BUT the router does not remount this component.
    // So we need to reset everything and not depend on componentDidMount
    if ((isEmpty(prevModel) && !isEmpty(model)) || (!isEmpty(model) && model.id !== prevModel.id)) {
      this.updateEnabledPermissions(model);
    }
  }

  updateEnabledPermissions(roleModel) {
    this.collection.clearFilters();

    const enabledPermissions =
      !isEmpty(roleModel) && roleModel.get('id') ? new Set(roleModel.get('permissions')) : new Set();
    const theLabelData = roleModel.id && roleModel.get('label_metadata');
    const hasLabelData = !isEmpty(theLabelData);

    // should already be normalized in the collection
    this.collection.each((permModel) => {
      permModel.set('isEnabled', enabledPermissions.has(permModel.get('permission')));

      if (hasLabelData && theLabelData[permModel.get('permission')]) {
        permModel.set('label_metadata', theLabelData[permModel.get('permission')]);
        permModel.set('labelBasedAccess', true);
      }
    });
  }

  handleTabChange = (selectedTabId) => {
    this.setState({ selectedTabId });
  };

  handleSave = () => {
    const { form, model, $rbac } = this.props;

    const { valid } = form;
    const { role_name, role_description } = form.getValues();

    if (valid) {
      const enabledPerms = this.collection.models.filter((m) => m.get('isEnabled') === true);
      const permissions = enabledPerms?.map((p) => p.attributes.permission) || [];

      // For every member of the collection, we form a meta data node .  . .
      const labelsMetaData = {};

      this.collection.each((permModel) => {
        if (!isEmpty(permModel.get('label_metadata'))) {
          labelsMetaData[permModel.get('permission')] = permModel.get('label_metadata');
        }
      });

      if (model && model.id) {
        return $rbac
          .editRole(model.id, { role_name, role_description, permissions, labels: labelsMetaData })
          .then(() => {
            showSuccessToast('Role Updated Successfully');
          });
      }
      return $rbac.createRole({ role_name, role_description, permissions, labels: labelsMetaData }).then((newModel) => {
        if (newModel && newModel.id) {
          showSuccessToast('Role Created Successfully');
          $rbac.navigateToRole(newModel.id);
        }
      });
    }
    return false;
  };

  handleCancel = () => {
    const { $rbac } = this.props;
    $rbac.navigateToRbacRoles();
  };

  render() {
    const { model, viewOnly, form } = this.props;
    const { selectedTabId } = this.state;
    const { valid } = form;

    const tabs = [
      {
        id: 'permissions',
        title: 'Permissions',
        panel: <RbacRolePermissions viewOnly={viewOnly} model={model} collection={this.collection} />
      }
    ];

    if (!isEmpty(model) && model.get('id')) {
      tabs.push({
        id: 'users',
        title: 'Users',
        panel: <RbacRoleUsers viewOnly={viewOnly} model={model} role="tabpanel" />
      });
    }

    return (
      <>
        <Flex justifyContent="space-between">
          <PageHeading title="Manage RBAC Role" icon="shield" flex={1} />
          <Flex m={10} flex={1} justifyContent="flex-end">
            <Button onClick={this.handleSave} text="Save" mr={2} intent="primary" disabled={!valid} />
            <Button onClick={this.handleCancel} text="Cancel" />
          </Flex>
        </Flex>
        <Box p={1}>
          <Field readOnly={viewOnly} large name="role_name" placeholder="Administrators">
            <InputGroup />
          </Field>

          <Field
            readOnly={viewOnly}
            large
            name="role_description"
            placeholder="Users who have access to amazing things!"
          >
            <InputGroup />
          </Field>
        </Box>
        <Box overflow="hidden" display="flex" flex={1} flexDirection="column">
          <BorderedTabs
            selectedTabId={selectedTabId}
            onChange={this.handleTabChange}
            selectedTabBackgroundColor="dialogBackground"
            overflow="hidden"
            width="auto"
            tabIndent={16}
            minimal
            mb="4px"
          >
            {tabs.map((tab) => (
              <BorderedTab key={tab.id} id={tab.id} title={tab.title} panel={tab.panel} />
            ))}
          </BorderedTabs>
        </Box>
      </>
    );
  }
}

export default RbacRoleDetailsForm;
